import { connect } from "react-redux";
import {  getUserByLevel } from "../../actions/adminAction";
import {  getScreenshot, outputshot } from "../../actions/screenshotAction";
import { useNavigate } from "react-router-dom"; 
import { useState, useEffect } from "react"; 
import "./style.css";
import Nav from "../../components/Nav";
import Select from 'react-select'
import Loading from "../../components/modals/loading";
import Confirm from "../../components/modals/confirm";
import { BiSolidUpArrow, BiSolidDownArrow } from "react-icons/bi";
import { FaRegSquare, FaRegCheckSquare } from "react-icons/fa";

function ScreenshotDownloadPage(props) {
  const screenshotSortItemList = [
    { name: "user_login", sortKey: "down", title: "帳號" },
    { name: "seq", sortKey: "down", title: "順序" },
    { name: "phy_id", sortKey: "down", title: "身分證字號" },
    { name: "cert_type", sortKey: "down", title: "證件" },
    { name: "name", sortKey: "down", title: "姓名" },
    { name: "number", sortKey: "down", title: "戶號" },
    { name: "stock_code", sortKey: "down", title: "股號" },
    { name: "right_to_vote", sortKey: "down", title: "股數" },
    { name: "stock_name", sortKey: "down", title: "股名" },
  ];

  const localUser = JSON.parse(sessionStorage.getItem("user"));
  const navigate  = useNavigate(); 
  const [ users, setUsers ] = useState([]);
  const [ userLogin, setUserLogin ] = useState("");
  const [ stockCode, setStockCode ] = useState("");
  const [ specicName, setSpecicName ] = useState("");
  const [ mergeUnit, setMergeUnit ] = useState(1);
  const [ yearSelected, setYearSelected ] = useState(null);
  const [ yearOptions, setYearOptions ] = useState([]);
  const currentYear = new Date().getFullYear();
  const [ htmlContent, setHtmlContent ] = useState('');
  const [ loading, setLoading ] = useState(false);
  const [ showSearchResult, setShowSearchResult ] = useState(false);
  const [ result, setResult ] = useState("");
  const [ resultList, setResultList ] = useState(null);
  const [ sortSelect, setSortSelect ] = useState("user_login");
  const [ sortList, setSortList ] = useState(screenshotSortItemList);
  const [ selectedScreenshot, setSelectedScreenshot ] = useState([]);
  const [ selectAll, setSelectAll ] = useState(false);
  const [ certTypeList, setCertTypeList ] = useState([]);
  const [ selectedUser, setSelectedUser ] = useState([]);
  const [ userFocus, setUserFocus ] = useState(false);
  const [ userSelectAll, setUserSelectAll ] = useState(false);

  async function getUser() {
    const result = await getUserByLevel(2, localUser.accessToken);
    if(result != null ) {
      setUsers(result);
    } else {
      logOut();
    }
  };

  useEffect(() => {
    if(localUser) {
      if(localUser.account_level === 1) {
        getUser();
        setUserLogin("all");
      } else {
        setUserLogin(localUser.user_login);
      }
    }
  }, []);

  useEffect(() => {
    const options = [];
    
    for (let year = 2023; year <= currentYear + 1; year++) {
      options.push({ value: year, label: (year - 1911).toString() });
    }

    setYearOptions(options);
    const currentYearOption = options.find((option) => option.value === currentYear);
    setYearSelected(currentYearOption);
  }, []);

  useEffect(() => {
    const tempSelectedUser = [...selectedUser];
    if(localUser.account_level === 1) {
      users.forEach(userItem => {
        const index = selectedUser.findIndex(user => user === userItem.user_login);
        if(index === -1 && userItem.user_login !== "000") {
          tempSelectedUser.push(userItem.user_login);
        }
      })
      setUserSelectAll(true);
    } else {
      const index = selectedUser.findIndex(user => user === localUser.user_login);
        if(index === -1 && localUser.user_login !== "000") {
          tempSelectedUser.push(localUser.user_login);
        }
    }
    setSelectedUser(tempSelectedUser);
  }, [users])

  useEffect(() => {
    function handleClickOutside(event) {
      const userSelectBlock = document.querySelector('.user-select-items');
      if (userSelectBlock && !userSelectBlock.contains(event.target)) {
        setUserFocus(false);
      }
    }
  
    // 添加點擊事件監聽器
    document.addEventListener('click', handleClickOutside);
  
    // 在組件卸載時清理事件監聽器
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [userFocus]);

  const logOut = () => {
    sessionStorage.clear();
    navigate("/")
  }

  const handleStockCodeChange = (e) => {
    setStockCode(e.target.value);
    setHtmlContent("");
  }

  const handleUserChange = (e) => {
    setUserLogin(e.target.value);
    setHtmlContent("");
  };

  const handleSpecicNameChange = (e) => {
    setSpecicName(e.target.value);
    setHtmlContent("");
  };

  const handleMergeUnitChange = (e) => {
    setMergeUnit(e.target.value);
    setHtmlContent("");
  };

  // const handleCertTypeChange = (e) => {
  //   setCertType(e.target.value);
  //   setHtmlContent("");
  // };

  const handleSearch = async () => {
    setLoading(true);
    setSelectedScreenshot([]);
    setHtmlContent("");
    let tempCertType = [...certTypeList];
    if(tempCertType.includes("-1")) {
      tempCertType = ["0","1","2","3","4","5"]
    }
    const result = await getScreenshot(selectedUser, stockCode, specicName, tempCertType, yearSelected.label, localUser.user_login, localUser.accessToken);
    if(result != null) {
      setLoading(false);
      setResultList(result);
      setShowSearchResult(true);
      setResult("搜索成功");
    } else{
      setResult("搜索失敗");
    }
  };

  const handleYearChange = (selected) => {
    setYearSelected(selected);
    setHtmlContent("");
  };

  const handleOnHideSearchResult = () => {
    setShowSearchResult(false);
  };

  const handleSort = (sortKey) => {
    setSortSelect(sortKey);
    handleSortChange(sortKey)
  };

  const handleSortChange = (sortKey) => {
    let nextSort = "";
    let tempSortedList = [...resultList];
    let tempSortItemList = [...sortList];
    tempSortItemList.map((content) => {
      if (content.name === sortKey) {
        nextSort = content.sortKey === "down" ? "up" : "down";
        sortByKey(tempSortedList, sortKey, content.sortKey);
        content.sortKey = nextSort;
        return content.sortKey
      }
      return null
    })
    setSortList(tempSortItemList);
    setResultList(tempSortedList);
  };

  const sortByKey = (array, sortKey, sortRule) => {
    if (sortRule === "down") {
      return array.sort((a, b) => {
        return ((a[sortKey] < b[sortKey]) ? -1 : ((a[sortKey] > b[sortKey]) ? 1 : 0));
      });
    }
    else {
      return array.sort((a, b) => {
        return ((a[sortKey] > b[sortKey]) ? -1 : ((a[sortKey] < b[sortKey]) ? 1 : 0));
      });
    }
  };

  const renderSortIcon = (sortKey) => {
    return sortKey === "down" ? <BiSolidDownArrow className="sort-icon" /> : <BiSolidUpArrow className="sort-icon" />
  };

  const handleToggleScreenshot = (screenshot) => {
    const tempScreenshotList = [...selectedScreenshot];
    const existingItemIndex = tempScreenshotList.findIndex(item => item.number === screenshot.number);
    if (existingItemIndex !== -1) {
      // 如果存在，删除该项
      tempScreenshotList.splice(existingItemIndex, 1);
    } else {
      // 如果不存在，添加新项
      tempScreenshotList.push(screenshot);
    }
    setSelectedScreenshot(tempScreenshotList);

    if(selectAll) {
      setSelectAll(false);
    }
  };

  const handleToggleAllScreenshot = () => {
    let tempScreenshotList = [...selectedScreenshot];
    for(let i=0; i<resultList.length; i++) {
      if(!selectAll) {
        tempScreenshotList.push(resultList[i]);
      } else {
        tempScreenshotList = [];
      }
    }
    setSelectedScreenshot(tempScreenshotList);
    if(selectAll) {
      setSelectAll(false);
    } else {
      setSelectAll(true);
    }
  };

  const handleOutputScreenshot = async () => {
    setLoading(true);
    const pic_path = [];
    selectedScreenshot.forEach(screenshot => {
      pic_path.push(screenshot.path);
    });
    const result = await outputshot(userLogin, mergeUnit, pic_path, localUser.accessToken);
    if(result) {
      setHtmlContent(result.url);
      setLoading(false);
    } else {
      setLoading(false);
      window.alert("產生截圖失敗");
    }
    
  }

  const handleTranslateCertType = (cert_type) => {
    switch (cert_type) {
      case 0:
        return("沒正本");
      case 1:
        return("戶口名簿");
      case 2:
        return("戶籍");
      case 3:
        return("身分證");
      case 4:
        return("健保卡");
      case 5:
        return("駕照");
      default:
        return("沒正本");
    }
  }

  const certTypeChange = (e) => {
    let tempCertTypeList = [...certTypeList];
    const inclide = tempCertTypeList.includes(e.target.id);
    if(!inclide) {
      tempCertTypeList.push(e.target.id);
    } else {
      tempCertTypeList = tempCertTypeList.filter(id => id !== e.target.id);
    }
    setCertTypeList(tempCertTypeList);
  }

  const handleUserSelected = (user_login) => {
    const tempUserSelectedList = [...selectedUser];
    if(user_login === "ALL") {
      if(!userSelectAll) {
        users.forEach(userItem => {
          const index = selectedUser.findIndex(user => user === userItem.user_login);
          if(index === -1 && userItem.user_login !== "000") {
            tempUserSelectedList.push(userItem.user_login);
          }
        });
        setUserSelectAll(true);
      } else {
        tempUserSelectedList.length = 0;
        setUserSelectAll(false);
      }
      
    } else {
      const index = selectedUser.findIndex(user => user === user_login);
      if(index === -1) {
        tempUserSelectedList.push(user_login);
        const isAllUsersSelected = users
          .filter(user => user.user_login !== "000")
          .every(user => tempUserSelectedList.includes(user.user_login)); 
        if(isAllUsersSelected) {
          setUserSelectAll(true);
        }
      } else {
        tempUserSelectedList.splice(index, 1);
        setUserSelectAll(false);
      }
      
    }
    setSelectedUser(tempUserSelectedList);
  }

  const handleUserChecked = (user_login) => {
    const userIndex = selectedUser.findIndex(user => user === user_login);
    if(userIndex !== -1 ) {
      return(true);
    } else {
      return(false);
    }
  }

  return(
    <>
    <Nav/>
    <div className="screenshot-root">
      <div className="screenshot-title">電投表</div>
      <div className="screenshot-data-form">
        <div className="user-select-block">
          <div className="title">年份 </div>
          <Select className="user-select" value={yearSelected} options={yearOptions} onChange={handleYearChange}/>
        </div>
        <div className="user-select-block">
          <div className="title">使用者 </div>
          <div className='user-select-items relative '>
            <input className="user-select" type="text" value={selectedUser.join(',')} onFocus={() => setUserFocus(true)} />
            {userFocus && localUser.account_level == 1? 
              <div className='absolute w-full p-2 border z-[999] bg-[#FFFFFF] rounded-md user-items'>
                <div className='cursor-pointer'>
                    <input type="checkbox" checked={userSelectAll} onClick={() => handleUserSelected("ALL")}/>
                    {"ALL"}
                  </div>
                {users.map((item, index) => {
                  if(item.user_login != "000")
                  return(
                    <div className='cursor-pointer' key={index}>
                      <input type="checkbox" checked={handleUserChecked(item.user_login)} onClick={() => handleUserSelected(item.user_login)}/>
                      {item.user_login}
                    </div>
                  )
                })}
              </div> : ""}
          </div>
        </div>
        <div className="stock-code-block">
          <div className="title">股號或股名 </div>
          <input className="stock-code-input" type="text" value={stockCode} onChange={e => handleStockCodeChange(e)}/>
        </div>
        <div className="stock-code-block">
          <div className="title">戶名 </div>
          <input className="stock-code-input" type="text" value={specicName} onChange={e => handleSpecicNameChange(e)}/>
        </div>
        <div className="cert-type-block">
          <div className="title">證件 </div>
          <div className="cert-type-list">
            <div>
              <input type="checkbox" id="-1" name="-1" onClick={e => certTypeChange(e)}/>
              <label for="-1">全顯示</label>
            </div>
            <div>
              <input type="checkbox" id="0" name="0" onClick={e => certTypeChange(e)}/>
              <label for="0">沒正本</label>
              <input type="checkbox" id="1" name="1" onClick={e => certTypeChange(e)}/>
              <label for="1">戶口名簿</label>
              <input type="checkbox" id="2" name="2" onClick={e => certTypeChange(e)}/>
              <label for="2">戶籍</label>
            </div>
            <div>
              <input type="checkbox" id="3" name="3" onClick={e => certTypeChange(e)}/>
              <label for="3">身分證</label>
              <input type="checkbox" id="4" name="4" onClick={e => certTypeChange(e)}/>
              <label for="4">健保卡</label>
              <input type="checkbox" id="5" name="5" onClick={e => certTypeChange(e)}/>
              <label for="5">駕照</label>
            </div>
          </div>
          
        </div>
        <div className="merge-unit-select-block">
          <div className="title">格式 </div>
            <select className="user-select" onChange={e => handleMergeUnitChange(e)} value={mergeUnit} defaultValue={1}>
              <option key={1} value={1}> 單頁1筆</option>
              <option key={2} value={2}> 單頁2筆</option>
              <option key={3} value={3}> 單頁3筆</option>
              <option key={4} value={4}> 單頁4筆</option>
              <option key={5} value={6}> 單頁6筆</option>
              <option key={6} value={8}> 單頁8筆</option>
            </select>
        </div>
      </div>
      <button className="margin-top" onClick={handleSearch}>查詢</button>
      {resultList ? <a className="show-button" href={"https://5205.tw/csvcache/" + localUser.user_login + "_searchshot.csv"} target="_blank">輸出列表</a> : ""}
      {result ? 
      <div className="screenshot-display">
        <table className="screenshot-table">
          <thead>
            <th>編號</th>
            <th>{selectAll ? <FaRegCheckSquare className="stock-select-icon"  onClick={() => handleToggleAllScreenshot()}/> : <FaRegSquare className="stock-select-icon"  onClick={() => handleToggleAllScreenshot()}/>} </th>
            {screenshotSortItemList.map((content) => {
              return(
                <th className="pointer" onClick={() => { handleSort(content.name) }}>
                  {content.title}
                  {sortSelect === content.name ?
                    renderSortIcon(content.sortKey)
                    : null}
                </th>
              )
            })}
          </thead>
          <tbody>
            {resultList && resultList.length != 0 && resultList.map((screenshot, index) => {
              const existingItemIndex = selectedScreenshot.findIndex(item => item.number === screenshot.number);
              return(
                <tr className={`hover ${index % 2 != 0 ? "odd-row" : ""}`}>
                  <td>{index + 1}</td>
                  <td> {existingItemIndex !== -1 ? <FaRegCheckSquare onClick={() => handleToggleScreenshot(screenshot)}/> : <FaRegSquare onClick={() => handleToggleScreenshot(screenshot)}/>}</td>
                  <td>{screenshot.user_login}</td>
                  <td>{screenshot.seq}</td>
                  <td>{screenshot.phy_id}</td>
                  <td>{handleTranslateCertType(screenshot.cert_type)}</td>
                  <td>{screenshot.name}</td>
                  <td>{screenshot.number}</td>
                  <td>{screenshot.stock_code}</td>
                  <td>{screenshot.right_to_vote}</td>
                  <td>{screenshot.stock_name}</td>
                </tr>
              )
            })}
          </tbody>
        </table>
      </div> : ""}
      <div className="button-row">
        {selectedScreenshot.length != 0 ? <div className="selected-count-text">{"總共勾選: " + selectedScreenshot.length + "張"}</div> : ""}
        {selectedScreenshot.length != 0 ? <button className='submit-button' onClick={handleOutputScreenshot}>產生截圖</button> : ""}
        {htmlContent != "" ? <a className="show-button" href={htmlContent} target="_blank">開啟頁面</a> : ""}
      </div>
    </div>
    <Loading loading={loading}/>
    <Confirm
      show={showSearchResult}
      title={result}
      onHide={handleOnHideSearchResult}
      message={result}
      ScreenShotDownload={true}/>

    </>
  )
}

export default connect(
  null,
  {  }
)(ScreenshotDownloadPage);