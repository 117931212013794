import { S_1_0 } from "../configs/config";

export const getStock = async () => {
  try {
    const response = await fetch(`${S_1_0}/stock/list/`, {
      method: "GET",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
      },
    });
    if (response.ok) {
      const stock = await response.json();
      return stock;
    } else {
      return null;
    }
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
};

export const removeStock = async (id, token) => {
  try {
    const response = await fetch(`${S_1_0}/stock/delete/`, {
      method: "POST",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        "Authorization": "Bearer " + token,
      },
      body: JSON.stringify(
        id
      ),
    });
    if (response.ok) {
      return true;
    } else {
      return false;
    }
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
};

export const editStock = async (ids, updateFields, token) => {
  try {
    const response = await fetch(`${S_1_0}/stock/edit/`, {
      method: "POST",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        "Authorization": "Bearer " + token,
      },
      body: JSON.stringify({
        ids: ids,
        editdata: updateFields,
      }),
    });
    if (response.ok) {
      return true;
    } else {
      return false;
    }
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
};

export const addStock = async (newStock, currentYear, token) => {
  try {
    const response = await fetch(`${S_1_0}/stock/addstock/`, {
      method: "POST",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        "Authorization": "Bearer " + token,
      },
      body: JSON.stringify([
          {
            "year": newStock.meeting_time.split(" ")[0].split("-")[0] - 1911,
            "stock_code": newStock.stock_code,
            "stock_name": newStock.stock_name,
            "gift_item": newStock.gift_item,
            "meeting_type": newStock.meeting_type,
            "meeting_time": newStock.meeting_time,
            "agent": newStock.agent,
            "bypass_notification": newStock.bypass_notification,
            "deadline": newStock.deadline,
            "behalf": 1,
            "behalf_price": newStock.behalf_price,
            "acquisition": 1,
            "acq_price": newStock.acq_price,
            "finalbuy_time": newStock.finalbuy_time,
            "note": newStock.note
          }
      ]),
    });
    if (response.ok) {
      return true;
    } else {
      return false;
    }
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
};

export const getStockBeforeDeadline = async (user_id, user_login, token) => {
  try {
    const response = await fetch(`${S_1_0}/stock/newlistwithdeadline/?user_id=${user_id}&user_login=${user_login}`, {
      method: "GET",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        "Authorization": "Bearer " + token,
      },
    });
    if (response.ok) {
      const stock = await response.json();
      return stock;
    } else {
      return null;
    }
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
};

export const uploadStockPic = async (stock, file, token) => {
  const fileType = file.type.split("/")[1];
  let formData = new FormData();
  formData.append(
    "fileToUpload[]",
    file
  );
  formData.append(
    "fileInfo",
    JSON.stringify([{
      "type":"giftpic",
      "id":stock.id,
      "FileName":stock.stock_code + "." + fileType,
  }]));
  try {
    const response = await fetch(`${S_1_0}/file/upload/`, {
      method: "POST",
      mode: "cors",
      headers: {
        "Authorization": "Bearer " + token,
      },
      body: formData
    });
    if (response.ok) {
      return true;
    } else {
      return false;
    }
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
}

