import React from "react";
import { useState, useEffect } from "react"; 
import { Modal, Button, Form } from "react-bootstrap";
import "../../styles/newStock.css";
import "../../styles/SignUp.css";
import 'bootstrap/dist/css/bootstrap.min.css';


export default function SignUp(props) {
  const [state, setState] = useState({ 
    year: 0,
    stock_code:  "", 
    stock_name: "", 
    gift_item: "", 
    deadline: "", 
    meeting_time: "", 
    agent: "", 
    behalf_price: 0, 
    acq_price: 0, 
    bypass_notification: 0, 
    finalbuy_time: "",
    note: "",
    meeting_type: 0,
    gift_category: 0,
  });

  useEffect(() => {
    window.onpopstate = () => {
      if (document.getElementById("modalConfirm")) {
        props.dismiss();
      }
    };
  }, []);

  useEffect(() => {
    if(props.data) {
      setState({ 
        year: props.data === null ? "" : props.data[0].year, 
        stock_code: props.data === null ? "" : props.data[0].stock_code, 
        stock_name: props.data === null ? "" : props.data[0].stock_name, 
        gift_item: props.data === null ? "" : props.data[0].gift_item, 
        deadline: props.data === null ? "" : props.data[0].deadline.split(" ")[0], 
        meeting_time: props.data === null ? "" : props.data[0].meeting_time.split(" ")[0], 
        agent: props.data === null ? "" : props.data[0].agent, 
        behalf_price: props.data === null ? 0 : props.data[0].behalf_price, 
        acq_price:  props.data === null ? 0 : props.data[0].acq_price, 
        bypass_notification: props.data === null ? 0 : props.data[0].bypass_notification, 
        finalbuy_time: props.finalbuy_time === null ? "" : props.data[0].finalbuy_time.split(" ")[0],
        note: props.data === null ? "" : props.data[0].note,
        meeting_type: props.data === null ? 0 : props.data[0].meeting_type,
        gift_category: props.data === null ? 0 : props.data[0].gift_category === null ? 0 : props.data[0].gift_category,
      })
    }
  }, [props.data]);

  const handleOnHide = () => {
    props.onHide();
  };

  const handleOnSave = async () => {
    if(state.meeting_time != "") {
      const data = state;
      data.deadline = data.deadline + " 23:59:59.000";
      data.finalbuy_time = data.finalbuy_time + " 23:59:59.000";
      data.meeting_time = data.meeting_time + " 23:59:59.000";
      props.function(data);
    } else {
      window.alert("請輸入開會時間");
    }
    
  };

  const handelChange = e => {
    switch (e.target.id) {
      case "behalf_price":
      case "acq_price" :
      case "gift_category":
        setState({ ...state, [e.target.id]: e.target.valueAsNumber });
        break;
      case "bypass_notification" :
      case "meeting_type":
        setState({ ...state, [e.target.id]: parseInt(e.target.value) });
        break;
      default:
        setState({ ...state, [e.target.id]: e.target.value });
        break;
    }
  };

  return (
    <Modal show={props.show} onHide={(props.onHide, handleOnHide)} animation={false} size="lg" 
      className="custom-modal" 
      aria-labelledby="contained-modal-title-vcenter"
      centered>
      <Modal.Body>
        <div className="register-background">
          <h1 className="register-title">新增股票</h1>
          <Form className="register-form">
            <Form.Group className="register-group">
              <Form.Label className="register-label" >
                股號
              </Form.Label>
              <Form.Control
                bsPrefix="register-input"
                type="text"
                id="stock_code"
                value={state.stock_code}
                onChange={handelChange} />
            </Form.Group>
            <Form.Group className="register-group">
              <Form.Label className="register-label">
                股名
              </Form.Label>
              <Form.Control
                bsPrefix="register-input"
                type="text"
                id="stock_name"
                value={state.stock_name}
                onChange={handelChange} />
            </Form.Group>
            <Form.Group className="register-group">
              <Form.Label className="register-label">
                紀念品
              </Form.Label>
              <Form.Control
                bsPrefix="register-input"
                type="text"
                id="gift_item"
                value={state.gift_item}
                onChange={handelChange} />
            </Form.Group>
            <Form.Group className="register-group">
              <Form.Label className="register-label">
                紀念品類型
              </Form.Label>
              <Form.Control
                bsPrefix="register-input"
                type="number"
                id="gift_category"
                value={state.gift_category}
                onChange={handelChange} />
            </Form.Group>
            <Form.Group className="register-group">
              <Form.Label className="register-label">
                委託截止日期
              </Form.Label>
              <Form.Control
                bsPrefix="register-input"
                type="date"
                id="deadline"
                value={state.deadline}
                onChange={handelChange} />
            </Form.Group>
            <Form.Group className="register-group">
              <Form.Label className="register-label">
                開會日期
              </Form.Label>
              <Form.Control
                bsPrefix="register-input"
                type="date"
                id="meeting_time"
                value={state.meeting_time}
                onChange={handelChange} />
            </Form.Group>
            <Form.Group className="register-group">
              <Form.Label className="register-label">
                股代
              </Form.Label>
              <Form.Control
                bsPrefix="register-input"
                type="text"
                id="agent"
                value={state.agent}
                onChange={handelChange} />
            </Form.Group>
            <Form.Group className="register-group">
              <Form.Label className="register-label">
                代領價格
              </Form.Label>
              <Form.Control
                bsPrefix="register-input"
                type="number"
                id="behalf_price"
                value={state.behalf_price}
                onChange={handelChange} />
            </Form.Group>
            <Form.Group className="register-group">
              <Form.Label className="register-label">
                收購紀念品價格
              </Form.Label>
              <Form.Control
                bsPrefix="register-input"
                type="number"
                id="acq_price"
                value={state.acq_price}
                onChange={handelChange} />
            </Form.Group>
            <Form.Group className="register-group">
              <Form.Label className="register-label">
                免交單
              </Form.Label>
              <Form.Select
                className ="register-input select-input"
                id="bypass_notification"
                aria-label="Default select example"
                value={state.bypass_notification}
                onChange={handelChange} 
                style={{border: "1px solid #767676"}}>
                  <option>請選擇</option>
                  <option value="0">否</option>
                  <option value="1">是</option>
              </Form.Select>
            </Form.Group>
            <Form.Group className="register-group">
              <Form.Label className="register-label">
                最後買進日
              </Form.Label>
              <Form.Control
                bsPrefix="register-input"
                type="date"
                id="finalbuy_time"
                value={state.finalbuy_time}
                onChange={handelChange} />
            </Form.Group>
            <Form.Group className="register-group">
              <Form.Label className="register-label">
                交單備註
              </Form.Label>
              <Form.Control
                bsPrefix="register-input"
                type="text"
                id="note"
                value={state.note}
                onChange={handelChange} />
            </Form.Group>
          </Form>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleOnHide}>
          取消
        </Button>
        <Button variant="primary" onClick={handleOnSave}>
          {props.submit}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}