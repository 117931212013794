import React from "react";
import { Modal, Button } from "react-bootstrap";

export default function infoPopUp(props) {

  const handleOnHide = () => {
    props.onHide();
  };

  function padId(id) {
    const strId = String(id);
    const paddingLength = 8 - strId.length;
    
    if (paddingLength <= 0) {
      return strId;
    }
  
    const paddingZeros = '0'.repeat(paddingLength);
    return paddingZeros + strId;
  };

  function renderCompanyInfo() {
    if(props.data) {
        return(
          <div className="company-info-row">
            <span className="company-item">{props.data.stock_name}</span>
            <span className="company-item">{padId(props.data.attorneys_id)}</span>
            <span className="company-item">{props.data.submit_date.split(" ")[0]}</span>
            <span className="company-item">{props.data.auto_pic}</span>
            <span className="company-item">{props.data.manual_pic}</span>
            <span className="company-item">{parseInt(props.data.manual_pic) + parseInt(props.data.auto_pic)}</span>
            <span className="company-item">{props.data.note}</span>
          </div>
        )
    }
  }

  return(
    <Modal show={props.show} onHide={(props.onHide, handleOnHide)} animation={false} size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered>
      <Modal.Header>
        <Modal.Title className="new-id-title">{props.title}</Modal.Title>
      </Modal.Header>
      <Modal.Body> 
        <div className="company-data-form">
          <div className="company-title-bar">
            {props.sortList != null ? props.sortList.map((content) => {
              return (
                <button className="sort-btn">
                  <span className="account-title account-icon-btn">{content.title}</span>
                </button>
              )
            }) : null}
          </div>
          {props.displayType == "company" ? renderCompanyInfo() : null}
        </div>  
      </Modal.Body>
    </Modal>
  )
}