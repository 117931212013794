import React, { Component } from 'react';
import { connect } from "react-redux";
import Confirm from "../../components/modals/confirm";
import Nav from "../../components/Nav";
import { useState, useEffect } from "react"; 
import { BiSolidUpArrow, BiSolidDownArrow } from "react-icons/bi";
import { getUnshippedList, addShipment } from "../../actions/shipmentAction";
import { FaRegSquare, FaRegCheckSquare } from "react-icons/fa";
import { MDBInputGroup } from "mdbreact";
import "./style.css";

const UnshipedSortList = [
  { name: "stock_code", sortKey: "down", title: "股號" },
  { name: "stock_name", sortKey: "down", title: "股名" },
  { name: "gift_item", sortKey: "down", title: "紀念品" },
  { name: "unacq_count", sortKey: "down", title: "出貨" },
  { name: "acq_count", sortKey: "down", title: "收購" },
  { name: "void_count", sortKey: "down", title: "退單" },
  { name: "attorney_note", sortKey: "down", title: "交單備註" },
  { name: "total_count", sortKey: "down", title: "出貨備註" },
  { name: "behalf_price", sortKey: "down", title: "代領費" },
  { name: "behalf_price_total", sortKey: "down", title: "代領總價" },
  { name: "acq_price", sortKey: "down", title: "收購價" },
  { name: "acq_price_total", sortKey: "down", title: "收購總價" },
  { name: "gift_category", sortKey: "down", title: "類型" },
];

const UnderUnshipedSortList = [
  { name: "stock_code", sortKey: "down", title: "股號" },
  { name: "stock_name", sortKey: "down", title: "股名" },
  { name: "gift_item", sortKey: "down", title: "紀念品" },
  { name: "unacq_count", sortKey: "down", title: "出貨" },
  { name: "acq_count", sortKey: "down", title: "收購" },
  { name: "void_count", sortKey: "down", title: "退單" },
  { name: "attorney_note", sortKey: "down", title: "交單備註" },
  { name: "behalf_price", sortKey: "down", title: "代領費" },
  { name: "behalf_price_total", sortKey: "down", title: "代領總價" },
  { name: "acq_price", sortKey: "down", title: "收購價" },
  { name: "acq_price_total", sortKey: "down", title: "收購總價" },
];

function UnShipPage(props) {
  const [ unshippedList, setUnshippedList ] = useState([]);
  const [ unshippedSortSelect, setUnshippedSortSelect ] = useState("user_login");
  const [ sortUnshippedList, setSortUnshippedList ] = useState(UnshipedSortList);
  const [ toggleShipment, setToggleShipment ] = useState([]);
  const [ toggleAll, setToggleAll ] = useState(false);
  const [ showConfirm, setShowConfirm ] = useState(false);
  const [ searchText, setSearchText ] = useState("");
  const [ search, setSearch ] = useState("");
  const [ searchSortedList, setSearchSortedList ] = useState([]);

  const localUser = JSON.parse(sessionStorage.getItem("user"));
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const userLogin = urlParams.get('user_login');
  const yearSelected = urlParams.get('year');

  useEffect(()  => {
    getUnshipment();
  }, []);

  useEffect(() => {
    if(search != "") {
      let tempSortedList;
      if(unshippedList != null) {
        tempSortedList = unshippedList.filter(unShip => unShip.stock_code.includes(search) || unShip.stock_name.includes(search) || unShip.gift_item.includes(search));
        setSearchSortedList(tempSortedList);
      }
    } else {
      setSearchSortedList([]);
    }
    setToggleShipment([]);
    setToggleAll(false);
  }, [search, sortUnshippedList])

  async function getUnshipment() {
    const result = await getUnshippedList(yearSelected, userLogin, localUser.accessToken);
    
    if(result != null ) {
      const updatedShipment = result.map(shipment => ({
        ...shipment,
        shipped_content: "",
      }));
      setUnshippedList(updatedShipment);
    }
  }

  const handleUnshippedSort = (sortKey) => {
    setUnshippedSortSelect(sortKey);
    handleUnshippedSortChange(sortKey)
  };

  const handleUnshippedSortChange = (sortKey) => {
    let nextSort = "";
    let tempSortedList = [...unshippedList];
    let tempSortItemList = [...sortUnshippedList];
    tempSortItemList.map((content) => {
      if (content.name === sortKey) {
        nextSort = content.sortKey === "down" ? "up" : "down";
        sortByKey(tempSortedList, sortKey, content.sortKey);
        content.sortKey = nextSort;
        return content.sortKey
      }
      return null
    })
    setSortUnshippedList(tempSortItemList);
    setUnshippedList(tempSortedList);
  };

  const sortByKey = (array, sortKey, sortRule) => {
    if (sortRule === "down") {
      return array.sort((a, b) => {
        return ((a[sortKey] < b[sortKey]) ? -1 : ((a[sortKey] > b[sortKey]) ? 1 : 0));
      });
    }
    else {
      return array.sort((a, b) => {
        return ((a[sortKey] > b[sortKey]) ? -1 : ((a[sortKey] < b[sortKey]) ? 1 : 0));
      });
    }
  };

  const renderSortIcon = (sortKey) => {
    return sortKey === "down" ? <BiSolidDownArrow className="sort-icon" /> : <BiSolidUpArrow className="sort-icon" />
  };

  const handleToggleShipment = (shipment) => {
    const tempShipmentList = [...toggleShipment];
    const index = tempShipmentList.findIndex(tempShipment => tempShipment.attorney_id == shipment.attorney_id);
    
    if(index === -1) {
      const tempShipmentItem = {
        stock_code: shipment.stock_code,
        stock_name: shipment.stock_name,
        gift_item: shipment.gift_item,
        unacq_count: shipment.unacq_count,
        acq_count: shipment.acq_count,
        void_count: shipment.void_count,
        attorney_note: shipment.attorney_note,
        attorney_id: shipment.attorney_id,
        shipped_content: shipment.shipped_content
      }
      tempShipmentList.push(tempShipmentItem);
      setToggleShipment(tempShipmentList);
    } else {
      tempShipmentList.splice(index, 1);
      setToggleShipment(tempShipmentList);
    }
    setToggleAll(false);
  }

  const handleShipmentContent = (e, attorney_id) => {
    const tempShipmentList = [...unshippedList];
    const tempToggleShipmentList = [...toggleShipment];
    tempShipmentList.forEach(item => {
      if (item.attorney_id === attorney_id) {
        item.shipped_content = e.target.value;
      }
    });

    tempToggleShipmentList.forEach(item => {
      if (item.attorney_id === attorney_id) {
        item.shipped_content = e.target.value;
      }
    });
    setUnshippedList(tempShipmentList);
    setToggleShipment(tempToggleShipmentList);
  }

  const handleToggleAll = () => {
    const tempShipmentList = [...toggleShipment];
    if(toggleAll) {
      setToggleShipment([]);
      setToggleAll(false);
    } else {
      let dataList;
      if(search === "") {
        dataList = unshippedList;
      } else {
        dataList = searchSortedList;
      }
      dataList.forEach(shipment => {
        const index = tempShipmentList.findIndex(tempShipment => tempShipment.attorney_id == shipment.attorney_id);
        if(index === -1) {
          const tempShipmentItem = {
            stock_code: shipment.stock_code,
            stock_name: shipment.stock_name,
            gift_item: shipment.gift_item,
            unacq_count: shipment.unacq_count,
            acq_count: shipment.acq_count,
            void_count: shipment.void_count,
            attorney_note: shipment.attorney_note,
            attorney_id: shipment.attorney_id,
            shipped_content: shipment.shipped_content
          }
          tempShipmentList.push(tempShipmentItem);
          setToggleShipment(tempShipmentList);
        }
      });
      setToggleAll(true);
    }
  }

  const handleSubmit = async () => {
    let tempToggleShipment = [];
    toggleShipment.map((shipment) => {
      tempToggleShipment.push({
        attorney_id: shipment.attorney_id,
        shipped_content: shipment.shipped_content
      })
    })

    const result = await addShipment(tempToggleShipment, userLogin, yearSelected, localUser.accessToken);
    if (result) {
      setUnshippedList(result);
    } else {
      getUnshipment();
    }
    setToggleShipment([]);
    setToggleAll(false);
    handleOnHideConfirm();
  }

  function padId(id) {
    const strId = String(id);
    const paddingLength = 8 - strId.length;
    
    if (paddingLength <= 0) {
      return strId;
    }
  
    const paddingZeros = '0'.repeat(paddingLength);
    return paddingZeros + strId;
  }

  function formateTime(time) {
    const originalDate = new Date(time);

    // 提取年、月、日
    const year = originalDate.getFullYear().toString().slice(2); // 提取后两位
    const month = (originalDate.getMonth() + 1).toString().padStart(2, '0'); // 月份从0开始
    const day = originalDate.getDate().toString().padStart(2, '0');

    // 格式化为 "YY/MM/DD" 的字符串
    const formattedDateString = `${year}/${month}/${day}`;
    if(time == "0000/00/00") {
      return("00/00/00");
    } else {
      return(formattedDateString);
    }
  };

  const handleOnHideConfirm = () => {
    setShowConfirm(false);
    // setSelectedAttorneyId(null);
  };

  const handleOnShowConfirm = () => {
    setShowConfirm(true);
  };

  function handleSearchTextChange(e) {
    setSearchText(e.target.value);
    setSearch(e.target.value);
  };

  function handleKeyPress(e) {
    if(e.which === 13) 
    handleSearch();
  };

  function handleSearch() {
    setSearch(searchText);
  };

  const renderUnshipedList = () => {
    const renderUnshipedList = searchSortedList.length != 0 ? searchSortedList : search != "" ? [] : unshippedList;
    if(renderUnshipedList) {
      return(
          renderUnshipedList.map((shipment, index) => {
            const existingIndex = toggleShipment.some(tempShipment => tempShipment.attorney_id == shipment.attorney_id);
            return(
              <tr key={index} className={`hover ${index % 2 != 0 ? "odd-row" : ""}`}>
                {localUser && localUser.account_level === 1 ? <td>{existingIndex ? <FaRegCheckSquare onClick={() => handleToggleShipment(shipment)}/> : <FaRegSquare onClick={() => handleToggleShipment(shipment)}/>}</td> : ""}
                <td className='width-5'>{index + 1}</td>
                <td>{shipment.stock_code}</td>
                <td className='width-5'>{shipment.stock_name}</td>
                <td className="width-50">{shipment.gift_item}</td>
                <td className='width-5'>{shipment.unacq_count}</td>
                <td className='width-5'>{shipment.acq_count}</td>
                <td className='width-5'>{shipment.void_count}</td>
                <td className="width-30">{shipment.attorney_note}</td>
                {localUser && localUser.account_level === 1 ? <td className='width-30'><input className='note_input' type="text" value={shipment.shipped_content} onChange={(e) => handleShipmentContent(e, shipment.attorney_id)}/></td> : ""}
                <td className='width-7'>{shipment.behalf_price}</td>
                <td className='width-7'>{shipment.behalf_price_total}</td>
                <td className='width-5'>{shipment.acq_price}</td>
                <td className='width-7'>{shipment.acq_price_total}</td>
                {localUser && localUser.account_level === 1 ? <td className='width-5'>{shipment.gift_category}</td> : ""}
              </tr>
            )
          })
      )
    }
  }

  return(
    <>
      <div className="shipment-root">
        <Nav/>
        <div className="unshipment-manager-background">
          <div className="manager-title">{userLogin + "未出貨"}</div>
          <div className="main-display">
            <div className="stock-button-bar-user">
              <MDBInputGroup
                material
                containerClassName="search-block col-12"
                hint="Search.."
                onChange={handleSearchTextChange}
                onKeyPress={handleKeyPress}
                append={
                  <button
                    rounded
                    size="sm"
                    type="submit"
                    className="mr-auto search-button"
                    onClick={handleSearch}
                  >
                    Search
                  </button>
                }
              />
            </div>
            <div className="shipment-data-form">
              <div className='total-text'>
                <span>總代領價: {unshippedList.reduce((sum, shipment) => sum + shipment.behalf_price_total, 0)}</span>
                <span className='margin-left'>總收購價: {unshippedList.reduce((sum, shipment) => sum + shipment.acq_price_total, 0)}</span>
              </div>
              <table>
                <thead>
                  <tr>
                    {localUser && localUser.account_level === 1 ? <th>{toggleAll ? <FaRegCheckSquare onClick={() => handleToggleAll()}/> : <FaRegSquare onClick={() => handleToggleAll()}/>}</th> : ""}
                    <th>編號</th>
                    {localUser && localUser.account_level === 1 ?  UnshipedSortList.map((content) => {
                      return(
                        <th onClick={() => { handleUnshippedSort(content.name) }}>
                          {content.title}
                          {unshippedSortSelect === content.name ?
                            renderSortIcon(content.sortKey)
                            : null}
                        </th>
                      )
                    }) : UnderUnshipedSortList.map((content) => {
                      return(
                        <th onClick={() => { handleUnshippedSort(content.name) }}>
                          {content.title}
                          {unshippedSortSelect === content.name ?
                            renderSortIcon(content.sortKey)
                            : null}
                        </th>
                      )})}
                  </tr>
                </thead>
                <tbody>
                  {renderUnshipedList()}
                </tbody>
              </table>
              {toggleShipment.length != 0 ? <button className='submit-button' onClick={handleOnShowConfirm}>出貨</button> : ""}
            </div>
          </div>
        </div>
      </div>
      <Confirm
        show={showConfirm}
        onHide={handleOnHideConfirm}
        message={"確認以上是否出貨?"}
        shipmentList={toggleShipment}
        removeShipment={true}
        function= {handleSubmit}/>
    </>
  )
}

export default connect(
  null,
  { }
)(UnShipPage);