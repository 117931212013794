import { useNavigate } from "react-router-dom"; 
import { useState, useEffect } from "react"; 
import { connect } from "react-redux";
import { FormControl } from "react-bootstrap";
import { login } from "../../redux/actions";
import { useDispatch, useSelector } from "react-redux/";
import "./style.css";
import { setUser } from "../../actions"
  
// Hooks must be used inside a functional component 
function LoginPage(props) { 
  //Creating a state variable 
  const [account, setAccount] = useState(""); 
  const [password, setPassword] = useState(""); 
  const [isLogining, setIsLogIn] = useState(false);
  const dispatch = useDispatch();
  const navigate  = useNavigate(); 

  async function loginUser() {
    await login(account, password)
      .then((result) => {
        dispatch(setUser(result));
        if(result != null) {
          sessionStorage.setItem("user", JSON.stringify(result));
          if(result.account_level == 1) {
            navigate("/idmanagement");
          } else {
            navigate("/");
          }
        }
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
      });
  };

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      loginUser();
    }
  };
  
  return ( 
    <div className="login-background">
      <div className="login-container">
        <h3 className="service-title">
          5205 我愛零股
        </h3>
        <div className="login-box">
          <span className="box-hint">
            電子郵件
          </span>
          <FormControl
            disabled={isLogining}
            id="account"
            bsPrefix="login-input"
            // placeholder="電子郵件"
            type="email"
            aria-label="E-mail"
            aria-describedby="basic-addon1"
            onChange={(e) => { 
              setAccount(e.target.value); 
            }}/>
          <span className="box-hint">
            密碼
          </span>
          <FormControl
            disabled={isLogining}
            bsPrefix="login-input"
            id="password"
            type="password"
            // placeholder="密碼"
            aria-label="密碼"
            aria-describedby="basic-addon1"
            onChange={(e) => { 
              setPassword(e.target.value); 
            }}
            onKeyDown={handleKeyPress}/>
          <div
            className="login-btn-block">
            <button
              id="loginBtn"
              className="login-btn"
              disabled={isLogining}
              onClick={loginUser}>
              登入
            </button>
          </div>
        </div>
      </div>
      
    </div>
  ); 
} 

export default connect(
  null,
  { login }
)(LoginPage);