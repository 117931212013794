import React from "react";
import { useState, useEffect } from "react"; 
import { Modal, Button, Form } from "react-bootstrap";
import "../../styles/uploadScreenShot.css";
import 'bootstrap/dist/css/bootstrap.min.css';


export default function UploadScreenShot(props) {
  const [ uploadFiles, setUploadFile ] = useState([]);
  const maxFileSize = 20;

  useEffect(() => {
    window.onpopstate = () => {
      if (document.getElementById("modalConfirm")) {
        props.dismiss();
      }
    };
  }, []);

  const handleOnHide = () => {
    props.onHide();
  };

  const handleOnUpload = async () => {
    props.uploadFunction(uploadFiles);
    setUploadFile([]);
  };

  const handelUploadFile = (e) => {
    const allowedExtensions = ['png', 'jpg', 'jpeg'];
    let hasInvalidFile = false;
    if (Array.from(e.target.files).length > maxFileSize) {
      alert(`Cannot upload files more than ${maxFileSize}`);
      e.target.value = null;
    } else {
      const files = e.target.files;

      for (let i = 0; i < files.length; i++) {
        const file = files[i];
        const fileExtension = file.name.split('.').pop().toLowerCase();
  
        if (!allowedExtensions.includes(fileExtension)) {
          hasInvalidFile = true;
          break; // 如果有不符合条件的文件，就直接跳出循环
        }
      }

      if(hasInvalidFile) {
        window.alert('只允许上传 .png、.jpg 或 .jpeg 文件');
        // 清除文件输入框的值，防止用户上传非允许的文件
        e.target.value = '';
        return;
      } 

      setUploadFile(e.target.files);
    }
    
  }

  return (
    <div>
      {props.show && (
      <div className="custom-modal-bg"></div>
    )}
    <Modal show={props.show} onHide={(props.onHide, handleOnHide)} animation={false} size="lg" 
    className="custom-modal" 
    aria-labelledby="contained-modal-title-vcenter"
    centered>
      <Modal.Body>
      <div className="upload-phy-pic-background">
        <div className="upload-phy-pic-box">
          <h1 className="upload-phy-pic-title">上傳投票截圖</h1>
          <div className="upload-phy-pic-form">
            <div>
              <span className="upload-phy-pic-label">股號: </span>
              <span className="upload-phy-pic-text">{props.stock_id}</span>
            </div>
            <div className="upload-screen-shot-input">
              <span className="upload-phy-pic-label">上傳檔案: </span>
              <input type="file" multiple onChange={e => handelUploadFile(e)}/>
            </div>
          </div>
        </div>
      </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleOnHide}>
          取消
        </Button>
        <Button variant="primary" onClick={handleOnUpload}>
          上傳
        </Button>
      </Modal.Footer>
    </Modal>
    </div>
  );
}