import React from 'react';
import { connect } from "react-redux";
import Nav from "../../components/Nav"
import "./style.css";

function LoveStockPage(props) {
  return(
    <div className='about-me-root'>
      <Nav/>
      <div className="about-me-background">
        <div className="about-me-title">5205電投</div>
        <div className="about-me-main-display">
          <p className='text-sart'>
            <span className='text'>5205 包含以下功能：</span><br/><br/>
            <span className='text margin-1'>1 快速投票</span><br/><br/>
            <span className='text margin-1'>2 交單網站同步匯入已經電投，可委託代領的公司</span><br/><br/>
            <span className='text margin-1'>3 列印多種格式的電投表</span><br/><br/>
            <span className='text margin-1 bold red'>代領費低 + 收購價高 + ５２０５更優惠</span><br/><br/>
            <span className='text margin-1 bold'>給 所有期待零股小確幸的你，有更好的選擇</span>
          </p>
        </div>
      </div>
    </div>
  )
}

export default connect(
  null,
  {  }
)(LoveStockPage);