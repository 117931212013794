import React from "react";
import { Modal, Button, Form } from "react-bootstrap";
import "../../styles/loading.css";
import { useState, useEffect } from "react"; 
import 'bootstrap/dist/css/bootstrap.min.css';

export default function Loading(props) {

  return(
    <div className={`overlay ${props.loading ? "show" : "hide"}`}>
      <div id="loader" className={props.loading ? "show" : "hide"} />
    </div>
  )
}