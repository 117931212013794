import { useState, useEffect } from "react"; 
import { getAttorney, deleteAttorney, getAttorneyInfo, confirmAttorney, getAttorneyListByStock, voidAttorney, editAttorney } from "../../actions/attorneyAction";
import { AiFillEdit, AiOutlineDelete } from "react-icons/ai";
import { connect } from "react-redux";
import { getCSV } from "../../actions/adminAction";
import Nav from "../../components/Nav";
import Loading from "../../components/modals/loading";
import "./style.css";
import { FaRegSquare, FaRegCheckSquare } from "react-icons/fa";
import { BiSolidUpArrow, BiSolidDownArrow, BiPlus, BiMinus } from "react-icons/bi";
import Confirm from "../../components/modals/confirm";
import { MDBBtn, MDBInputGroup } from "mdbreact";
import Select from 'react-select'

function AttorneySummaryPage(props) {
  const attorneySortItemList = [
    { name: "user_login", sortKey: "down", title: "帳號" },
    { name: "nickname", sortKey: "down", title: "姓名" },
    { name: "stock_code", sortKey: "down", title: "股號" },
    { name: "stock_name", sortKey: "down", title: "股名" },
    { name: "auto_pic", sortKey: "down", title: "收購" },
    { name: "manual_pic", sortKey: "down", title: "不收購" },
    { name: "void_pic", sortKey: "down", title: "退" },
    { name: "total_pic", sortKey: "down", title: "總" },
    { name: "notes", sortKey: "down", title: "備註" },
    { name: "submit_date", sortKey: "down", title: "交單時間" },
    { name: "confirm", sortKey: "down", title: "狀態" },
  ];

  const stockSortItemList = [
    { name: "stock_code", sortKey: "down", title: "股號" },
    { name: "stock_name", sortKey: "down", title: "股名" },
    { name: "gift_item", sortKey: "down", title: "紀念品" },
    { name: "acq_price", sortKey: "down", title: "收購價" },
    { name: "auto_pic", sortKey: "down", title: "電" },
    { name: "manual_pic", sortKey: "down", title: "手" },
    { name: "void_pic", sortKey: "down", title: "退" },
    { name: "total_pic", sortKey: "down", title: "總" },
    { name: "note", sortKey: "down", title: "備註" },
    { name: "acq_all", sortKey: "down", title: "全收購" },
  ];

  const attorneyStockSortList = [
    { name: "meeting_time", sortKey: "down", title: "開會時間" },
    { name: "stock_name", sortKey: "down", title: "股名" },
    { name: "stock_code", sortKey: "down", title: "股號" },
    { name: "total_count", sortKey: "down", title: "收單份數" },
    { name: "confirm_count", sortKey: "down", title: "確認份數" },
    { name: "unconfirm_count", sortKey: "down", title: "尚未確認" },
    { name: "void_count", sortKey: "down", title: "退單份數" },
    { name: "remaining_count", sortKey: "down", title: "已確認 - 退單" },
  ]

  const attorneySubStockSortList = [
    { name: "user_login", sortKey: "down", title: "帳號" },
    { name: "nickname", sortKey: "down", title: "姓名" },
    { name: "attorneys_id", sortKey: "down", title: "單號" },
    { name: "confirm_count", sortKey: "down", title: "確認份數" },
    { name: "auto_pic", sortKey: "down", title: "收購" },
    { name: "manual_pic", sortKey: "down", title: "不收購" },
    { name: "void_pic", sortKey: "down", title: "退單份數" },
    { name: "note", sortKey: "down", title: "備註" },
  ]

  const [ attorneyStockList, setAttorneyStockList ] = useState([]);
  const [ attorneySortSelect, setAttorneySortSelect ] = useState("id");
  const [ attorneyStockSortSelect, setAttorneyStockSortSelect ] = useState("meeting_time");
  const [ attorneySubStockSortSelect, setAttorneySubStockSortSelect ] = useState("user_login");
  const [ stockSortSelect, setStockSortSelect ] = useState("stock_code");
  const [ sortAttorneyList, setSortAttorneyList ] = useState(attorneySortItemList);
  const [ sortAttorneyStockList, setSortAttorneyStockList ] = useState(attorneyStockSortList);
  const [ sortStockItemList, setSortStockItemList ] = useState(stockSortItemList);
  const [ sortAttorneySubStockList, setSortAttorneySubStockList ] = useState(attorneySubStockSortList);
  const [ stocklist, setStocklist ] = useState([]);
  const [ expandedRows, setExpandedRows ] = useState([]);
  const [ stockIdExpandedRows, setstockIdExpandedRows ] = useState([]);
  const [ attorneyDisplayType, setAttorneyDisplayType ] = useState("attorneyId");
  const [ toggleStockIds, setToggleStockIds ] = useState([]);
  const [ showConfirm, setShowConfirm ] = useState(false);
  const [ selectedAttorneyId, setSelectedAttorneyId ] = useState(null);
  const [ showAttorneyConfirm, setShowAttorneyConfirm ] = useState(false);
  const [ searchText, setSearchText ] = useState("");
  const [ search, setSearch ] = useState("");
  const [ searchAttorneyList, setSearchAttorneyList ] = useState([]);
  const [ searchAttorneyStockList, setSearchAttorneyStockList ] = useState([]);
  const [ attorneySubStockList, setAttorneySubStockList ] = useState([]);
  const [ toggleAttorneyAllStockId, setToggleAttorneyAllStockId ] = useState([]);
  const [ showAttorneyVoid, setShowAttorneyVoid ] = useState(false);
  const [ separateUploadArrorney, setSeparateUploadArrorney ] = useState(null);
  const [ showSeparateAttorneyVoid, setShowSeparateAttorneyVoid ] = useState(false);
  const [ yearSelected, setYearSelected ] = useState(null);
  const [ yearOptions, setYearOptions ] = useState([]);
  const [ showEditAttorney, setShowEditAttorney ] = useState(false);
  const [ editAttorneyData, setEditAttorneyData ] = useState(null);
  const [ selectedAttorney, setSelectedAttorney ] = useState([]);
  const [ selectedAllAttorney, setSelectedAllAttorney ] = useState(false);
  const [ showVoidSelectedAttorney, setShowVoidSelectedAttorney ] = useState(false);
  const [ showConfirmSelectedAttorney, setShowConfirmSelectedAttorney ] = useState(false);
  const [ loading, setLoading ] = useState(false);
  const [ state, setState ] = useState({stock: "", deadLine: ""});


  const localUser = JSON.parse(sessionStorage.getItem("user"));
  const currentYear = new Date().getFullYear();

  async function getAttorneyList() {
    if(state.deadLine !== "" || state.stock !== "") {
      if(attorneyDisplayType === "attorneyId") {
        const result = await getAttorney(yearSelected.label, state.stock, state.deadLine === "" ? "" : state.deadLine + " 23:59:59", localUser.accessToken);
        if(result != null ) {
          setStocklist(result);
          setLoading(false);
        } else {
          setStocklist([]);
          setLoading(false);
        }
      } else {
        const result = await getAttorneyListByStock(localUser.accessToken, yearSelected.label, state.stock, state.deadLine === "" ? "" : state.deadLine + " 23:59:59");
        if(result != null ) {
          setAttorneyStockList(result);
          setLoading(false);
        } else {
          setAttorneyStockList([]);
          setLoading(false);
        }
      }
    } else {
      setStocklist([]);
      setLoading(false);
    }
  }

  const combineData = (attorneyInfo, element) => {
    return {
      ...attorneyInfo,
      user_login: element.user_login,
      nickname: element.nickname,
      id: element.id,
      confirm: element.confirm,
      submit_date: element.submit_date,
      year: element.year,
      shipped: element.shipped,
    }
  };

  function padId(id) {
    const strId = String(id);
    const paddingLength = 8 - strId.length;
    
    if (paddingLength <= 0) {
      return strId;
    }
  
    const paddingZeros = '0'.repeat(paddingLength);
    return paddingZeros + strId;
  }

  useEffect(() => {
    const options = [];
    
    for (let year = 2023; year <= currentYear + 1; year++) {
      options.push({ value: year, label: (year - 1911).toString() });
    }

    setYearOptions(options);

    // 设置预选值为今年
    const currentYearOption = options.find((option) => option.value === currentYear);
    setYearSelected(currentYearOption);
  }, []);

  useEffect(() => {
    if(yearSelected) {
      setLoading(true);
      getAttorneyList();
    }
  }, [attorneyDisplayType, yearSelected]);

  

  useEffect(() => {
    if(search != "" && attorneyDisplayType === "attorneyId") {
      let tempAttorneyList;
      if(stocklist != null) {
        tempAttorneyList = stocklist.filter(attorney => ("" + padId(attorney.id)).includes(search) || attorney.user_login.includes(search) || attorney.stock_code.includes(search) || attorney.stock_name.includes(search));
        setSearchAttorneyList(tempAttorneyList);
      }
    } else if(search != "" && attorneyDisplayType === "stockId") {
      let tempAttorneyStockList;
      if(attorneyStockList != null) {
        tempAttorneyStockList = attorneyStockList.filter(attorney => attorney.stock_name.includes(search) || attorney.stock_code.includes(search));
        setSearchAttorneyStockList(tempAttorneyStockList);
      }
    } else {
      setSearchAttorneyList([]);
      setSearchAttorneyStockList([]);
    }
  }, [search]);

  const handleYearChange = (selected) => {
    setYearSelected(selected);
    setExpandedRows([]);
    setstockIdExpandedRows([]);
    setToggleAttorneyAllStockId([]);
    setToggleStockIds([]);
  };

  async function handleVoidAttorney(notes, isSeparateRemove, voidCount) {
    const result = await voidAttorney(toggleStockIds, localUser.user_login, localUser.id, notes, separateUploadArrorney, isSeparateRemove, voidCount, localUser.accessToken);
    if(result) {
      if(isSeparateRemove) {
        window.alert("單號: " + separateUploadArrorney.attorneyId + "退單" + voidCount +"筆成功");
        setLoading(true);
        handleOnHideSeparateAttorneyVoid();
        getAttorneyList();
      } else {
        setLoading(true);
        handleOnHideAttorneyVoid();
        getAttorneyList();
        setToggleStockIds([]);
      }
    }
  };

  async function handleEditAttorney(attorney, auto_pic, manual_pic) {
    const result = await editAttorney(attorney, auto_pic, manual_pic, localUser.accessToken);
    if(result) {
      handleOnHideEditAttorney();
      getAttorneyList();
    }
  };
  
  const reLoadWindows = () => {
    window.location.reload();
  };

  const renderSortIcon = (sortKey) => {
    return sortKey === "down" ? <BiSolidDownArrow className="sort-icon" /> : <BiSolidUpArrow className="sort-icon" />
  };

  const handleStockItemListSort = (sortKey) => {
    setStockSortSelect(sortKey);
    handleStockItemListSortChange(sortKey)
  };

  const handleStockItemListSortChange = (sortKey) => {
    let nextSort = "";
    let tempSortedList = [...stocklist];
    let tempSortItemList = [...sortStockItemList];
    tempSortItemList.map((content) => {
      if (content.name === sortKey) {
        nextSort = content.sortKey === "down" ? "up" : "down";
        tempSortedList.forEach(stock => {
          sortByKey(stock.stock_info, sortKey, content.sortKey);
        });
        content.sortKey = nextSort;
        return content.sortKey
      }
      return null
    })
    setSortStockItemList(tempSortItemList);
    setStocklist(tempSortedList);
  };

  const handleAttorneySort = (sortKey) => {
    setAttorneySortSelect(sortKey);
    handleAttorneySortChange(sortKey)
  };

  const handleAttorneySortChange = (sortKey) => {
    let nextSort = "";
    let tempSortedList = [];
    if(searchAttorneyList.length != 0) {
      tempSortedList = [...searchAttorneyList];
    } else {
      tempSortedList = [...stocklist];
    }
    let tempSortItemList = [...sortAttorneyList];
    tempSortItemList.map((content) => {
      if (content.name === sortKey) {
        nextSort = content.sortKey === "down" ? "up" : "down";
        sortByKey(tempSortedList, sortKey, content.sortKey);
        content.sortKey = nextSort;
        return content.sortKey
      }
      return null
    })
    setSortAttorneyList(tempSortItemList);
    if(searchAttorneyList.length != 0) {
      setSearchAttorneyList(tempSortedList);
    } else {
      setStocklist(tempSortedList);
    }
  };

  const handleAttorneyStockSort = (sortKey) => {
    setAttorneyStockSortSelect(sortKey);
    handleAttorneyStockSortChange(sortKey)
  };

  const handleAttorneyStockSortChange = (sortKey) => {
    let nextSort = "";
    let tempSortedList = [];
    if(searchAttorneyStockList.length != 0) {
      tempSortedList = [...searchAttorneyStockList];
    } else {
      tempSortedList = [...attorneyStockList];
    }
    let tempSortItemList = [...sortAttorneyStockList];
    tempSortItemList.map((content) => {
      if (content.name === sortKey) {
        nextSort = content.sortKey === "down" ? "up" : "down";
        sortByKey(tempSortedList, sortKey, content.sortKey);
        content.sortKey = nextSort;
        return content.sortKey
      }
      return null
    })
    setSortAttorneyStockList(tempSortItemList);
    if(searchAttorneyList.length != 0) {
      setSearchAttorneyStockList(tempSortedList);
    } else {
      setAttorneyStockList(tempSortedList);
    }
  };

  const handleAttorneySubStockSort = (sortKey, index) => {
    setAttorneySubStockSortSelect(sortKey);
    handleAttorneySubStockSortChange(sortKey, index)
  };

  const handleAttorneySubStockSortChange = (sortKey, index) => {
    let nextSort = "";
    let tempSortedList = [];
    if(searchAttorneyStockList.length != 0) {
      tempSortedList = [...searchAttorneyStockList];
    } else {
      tempSortedList = [...attorneyStockList];
    }
    let tempSortItemList = [...sortAttorneySubStockList];
    tempSortItemList.map((content) => {
      if (content.name === sortKey) {
        nextSort = content.sortKey === "down" ? "up" : "down";
        sortByKey(tempSortedList[index].attorneys, sortKey, content.sortKey);
        content.sortKey = nextSort;
        return content.sortKey
      }
      return null
    })
    setSortAttorneySubStockList(tempSortItemList);
    if(searchAttorneyList.length != 0) {
      setSearchAttorneyStockList(tempSortedList);
    } else {
      setAttorneyStockList(tempSortedList);
    }
  };

  const sortByKey = (array, sortKey, sortRule) => {
    if (sortRule === "down") {
      return array.sort((a, b) => {
        return ((a[sortKey] < b[sortKey]) ? -1 : ((a[sortKey] > b[sortKey]) ? 1 : 0));
      });
    }
    else {
      return array.sort((a, b) => {
        return ((a[sortKey] > b[sortKey]) ? -1 : ((a[sortKey] < b[sortKey]) ? 1 : 0));
      });
    }
  }

  const handelDeleteAttorney = async (notes) => {
    if(selectedAttorneyId != null) {
      const tempAttorneyList = [{
        id: selectedAttorneyId,
				void_note: notes
      }];
      const result = await deleteAttorney(tempAttorneyList, localUser.accessToken);
      if(result) {
        setLoading(true);
        getAttorneyList();
        handleOnHideConfirm();
        handleOnVoidSelectedAttorney();
      }
    } else if(selectedAttorney.length != 0) {
      const tempAttorneyList = [];
      selectedAttorney.forEach(async (element) => {
        tempAttorneyList.push({
          id: element.id,
				  void_note: notes
        })
      });
      const result = await deleteAttorney(tempAttorneyList, localUser.accessToken);
      if(result) {
        setLoading(true);
        getAttorneyList();
        handleOnHideConfirm();
        handleOnVoidSelectedAttorney();
      } else {
        window.alert("委託書刪除失敗");
      }
    }
    
  };

  const handleToggleSublist = (attorneyID) => {
    if (expandedRows.includes(attorneyID)) {
      setExpandedRows(expandedRows.filter((id) => id != attorneyID));
    } else {
      setExpandedRows([...expandedRows, attorneyID]);
    }
  };

  const handleToggleStockIdSublist = (attorneyID) => {
    if (stockIdExpandedRows.includes(attorneyID)) {
      setstockIdExpandedRows(stockIdExpandedRows.filter((id) => id != attorneyID));
    } else {
      setstockIdExpandedRows([...stockIdExpandedRows, attorneyID]);
    }
  };

  const handelAttorneyConfirm = async () => {
    setLoading(true);
    const confirmIdList  = [];

    if(selectedAttorney.length != 0) {
      selectedAttorney.forEach(attorney => {
        confirmIdList.push({id: attorney.id});
      });
      await confirmAttorney(confirmIdList, localUser.accessToken)
      .then((result) => {
        if (result) {
          setLoading(true);
          getAttorneyList();
          handleOnHideAttorneyConfirm();
          handleOnHideConfirmSelectedAttorney();
        }
      })
    } else {
      confirmIdList.push({id: selectedAttorneyId})
      const result = await confirmAttorney(confirmIdList, localUser.accessToken);
      if(result) {
        setLoading(true);
        getAttorneyList();
        handleOnHideAttorneyConfirm();
        handleOnHideConfirmSelectedAttorney();
      }
    }
    
  }

  const sumNumbersFromString = (str) => {
    const numbersArray = str.split(',').map(Number);
    const total = numbersArray.reduce((acc, num) => acc + num, 0);
    return (total);
  };

  const calculateSumAndDifference = (auto_pic, menul_pic, void_pic) => {
    const autoArray = auto_pic.split(',').map(Number);
    const menulArray = menul_pic.split(',').map(Number);
    const voidArray = void_pic.split(',').map(Number);
    const result = autoArray.reduce((sum, value, index) => {
      const sumMenul_pic = menulArray[index] || 0;
      const sumVoid_pic = voidArray[index] || 0;
      return sum + value + sumMenul_pic - sumVoid_pic;
    }, 0);
    return(result)
  };

  const handleAttorneyDisplayTypeChange = e => {
    setAttorneyDisplayType(e.target.value);
    setSearch("");
    setSearchText("");
    setExpandedRows([]);
    setstockIdExpandedRows([]);
    setToggleAttorneyAllStockId([]);
    setToggleStockIds([]);
    setSearchAttorneyStockList([]);
    setSearchAttorneyList([]);
    setStocklist([]);
    setAttorneyStockList([]);
    setState({stock: "", deadLine: ""});
  }

  function handelToogleStock(attorney, stock) {
    const tempToggleStockId = [...toggleStockIds];
    let alreadyCheck = false;
    let index = 0;

    toggleStockIds.forEach((toggleStock) => {
      if(toggleStock.stockId == attorney.stock_id && toggleStock.attorneyId == stock.attorneys_id) {
        index = toggleStockIds.indexOf(toggleStock);
        alreadyCheck = true;
      }
    })

    if(alreadyCheck) {
      tempToggleStockId.splice(index, 1);
    } else {
      tempToggleStockId.push({
        attorneyId: stock.attorneys_id, 
        stockId: attorney.stock_id, 
        stockCode: attorney.stock_code,
        confirm_count: stock.confirm_count,
        void_count: stock.void_pic
      });
    }
    
    setToggleStockIds(tempToggleStockId);
  };

  const handleOnShowConfirm = async (id) => {
    setShowConfirm(true);
    setSelectedAttorneyId(id);
  };

  const handleOnShowVoidSelectedAttorney = async (id) => {
    setShowVoidSelectedAttorney(true);
  };

  const handleOnShowConfirmSelectedAttorney = async (id) => {
    setShowConfirmSelectedAttorney(true);
  };

  const handleShowEditAttorney = (attorney) => {
    setShowEditAttorney(true);
    setEditAttorneyData(attorney);
  };

  const handleOnHideEditAttorney = () => {
    setShowEditAttorney(false);
    setEditAttorneyData(null);
  };

  const handleOnHideConfirm = () => {
    setShowConfirm(false);
    setSelectedAttorneyId(null);
  };

  const handleOnVoidSelectedAttorney = () => {
    setShowVoidSelectedAttorney(false);
    setSelectedAttorney([]);
    setSelectedAllAttorney(false);
  };

  const handleOnShowAttorneyConfirm = async (attorney) => {
    if(attorney.confirm == 0) {
      setShowAttorneyConfirm(true);
      setSelectedAttorneyId(attorney.id);
    }
  };

  const handleOnHideAttorneyConfirm = () => {
    setShowAttorneyConfirm(false);
  };

  const handleOnHideConfirmSelectedAttorney = () => {
    setShowConfirmSelectedAttorney(false);
    setSelectedAttorney([]);
    setSelectedAllAttorney(false);
  };

  const handleOnShowAttorneyVoid = () => {
    setShowAttorneyVoid(true);
  };

  const handleOnHideAttorneyVoid = () => {
    setShowAttorneyVoid(false);
  };

  const handleOnHideSeparateAttorneyVoid = () => {
    setShowSeparateAttorneyVoid(false);
  };

  function handleSearchTextChange(e) {
    setSearchText(e.target.value);
    setSearch(e.target.value);
    if(selectedAllAttorney) {
      toggleAllAttorney();
    }
  }

  function handleSearch() {
    setSearch(searchText);
  }

  function handleKeyPress(e) {
    if(e.which === 13) 
    handleSearch();
  }

  function handleConfirmToggle(stockId, attorneyId) {
    let confirm = false;
    toggleStockIds.forEach((toggleStock) => {
      if(toggleStock.stockId == stockId && toggleStock.attorneyId == attorneyId) {
        confirm = true;
      }
    })
    return confirm;
  }

  function handleAttorneySearch() {
    setLoading(true);
    getAttorneyList();
  }

  function handleToggleStockAllAttorney(attorneyItem) {
    const tempToggleStockId = [...toggleStockIds];
    const tempToggleAttorneyAllStockId = [...toggleAttorneyAllStockId];
    if(toggleAttorneyAllStockId.includes(attorneyItem.stock_id)) {
      let newToggleAttorneyAllStockId = tempToggleAttorneyAllStockId.filter(attorney => attorney != attorneyItem.stock_id);
      setToggleAttorneyAllStockId(newToggleAttorneyAllStockId);

      let filteredToggleStockId = tempToggleStockId.filter(stock => stock.stockId != attorneyItem.stock_id);
      setToggleStockIds(filteredToggleStockId);
    } else {
      tempToggleAttorneyAllStockId.push(attorneyItem.stock_id);
      setToggleAttorneyAllStockId(tempToggleAttorneyAllStockId);

      attorneyItem.attorneys.forEach((attorney) => {
        if(!toggleStockIds.some(item => item.attorneyId === attorney.attorneys_id && item.stockId == attorneyItem.stock_id)) {
          if(attorney.confirm_count != 0 && attorney.confirm_count - parseInt(attorney.void_pic) != 0 && attorney.shipped == 0) {
            tempToggleStockId.push(
            {attorneyId: attorney.attorneys_id, 
              stockId: attorneyItem.stock_id, 
              stockCode: attorneyItem.stock_code, 
              confirm_count: attorney.confirm_count, 
              void_count: attorney.void_pic}
            );
          }
        }
      })
      setToggleStockIds(tempToggleStockId);
    }
  };

  function handelSeprateUpload(attorneyItem, attorney) {
    if(attorney.confirm_count != 0 && attorney.confirm_count - attorney.void_pic != 0 && attorney.shipped != 1) {
      setSeparateUploadArrorney({
        attorneyId: attorney.attorneys_id, 
        stockId: attorneyItem.stock_id, 
        stockCode: attorneyItem.stock_code,
        confirm_count: attorney.confirm_count,
        void_count: attorney.void_pic
      })
      setShowSeparateAttorneyVoid(true);
    }
  };

  function formateTime(time) {
    const originalDate = new Date(time);
    // 提取年、月、日
    const year = originalDate.getFullYear().toString().slice(2); // 提取后两位
    const month = (originalDate.getMonth() + 1).toString().padStart(2, '0'); // 月份从0开始
    const day = originalDate.getDate().toString().padStart(2, '0');

    // 格式化为 "YY/MM/DD" 的字符串
    const formattedDateString = `${year}/${month}/${day}`;
    if(time == "0000/00/00") {
      return("00/00/00");
    } else {
      return(formattedDateString);
    }
  };

  async function handleDownLoadCSV() {
    const result = await getCSV( "attorneyList", yearSelected.label, localUser.id, "", localUser.accessToken);
    if(result) {
      const today = new Date();
      const options = {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
      };
      const formattedDate = today.toLocaleDateString('zh-TW', options).replace(/\//g, '');
      
      const blob = new Blob(["\ufeff" +result], { type: 'text/csv;charset=uft-8' });
      const link = document.createElement('a');
      link.href = URL.createObjectURL(blob);
      link.download = 'attorneyList_output_' + formattedDate + '.csv';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }

  const handelToggleAttorney = (attorneyItem) => {
    const tempSelectedAttorney = [... selectedAttorney];
    const existingItemIndex = tempSelectedAttorney.findIndex(item => item.id === attorneyItem.id);
    if(existingItemIndex != -1) {
      tempSelectedAttorney.splice(existingItemIndex, 1);
    } else {
      if(attorneyItem.shipped != 1) {
        tempSelectedAttorney.push(attorneyItem);
      }
    }
    setSelectedAttorney(tempSelectedAttorney);
    if(selectedAllAttorney) {
      setSelectedAllAttorney(false);
    }
  }

  const toggleAllAttorney = () => {
    if(selectedAllAttorney) {
      setSelectedAttorney([]);
      setSelectedAllAttorney(false);
    } else {
      const tempSelectedAttorney = [... selectedAttorney];
      const tempList = searchAttorneyList.length > 0 ? searchAttorneyList : stocklist;
      tempList.forEach(element => {
        const existingItemIndex = selectedAttorney.findIndex(item => item.id === element.id);
        if(existingItemIndex === -1 && element.shipped != 1) {
          tempSelectedAttorney.push(element);
        }
      });
      setSelectedAttorney(tempSelectedAttorney);
      setSelectedAllAttorney(true);
    }
  }

  const handleSearchInputChange = (e) => {
    setState({ ...state, [e.target.id]: e.target.value });
  }

  const renderStockInfo = (attorneyItem) => {
    
    const stockList = stocklist.filter(stock => stock.id === attorneyItem.id && attorneyItem.year == yearSelected.label);
    return (
      stockList.length != 0 && stockList[0].stock_info!= null ? 
      stockList[0].stock_info.map((stock, index) => {
        return(
          <tr className={`${index % 2 != 0 ? "odd-row" : ""}`}>
            <td>{stock.stock_code}</td>
            <td>{stock.stock_name}</td>
            <td>{stock.gift_item}</td>
            <td>{stock.acq_price}</td>
            <td className="num_colm_small">{stock.auto_pic}</td>
            <td className="num_colm_small">{stock.manual_pic}</td>
            <td className="num_colm_small">{stock.void_pic}</td>
            <td className="num_colm_small">{parseInt(stock.auto_pic) + parseInt(stock.manual_pic) - parseInt(stock.void_pic)}</td>
            <td>{stock.note}</td>
            <td>{stock.acq_all === "1" ? <FaRegCheckSquare /> : <FaRegSquare />}</td>
            <td>{attorneyItem.confirm === 0 ? <AiFillEdit  className="select-icon" onClick={() => handleShowEditAttorney(attorneyItem, stock)}/> : ""}</td>
          </tr>
        )
      }) : ""
    )
  };

  const renderAttorneyStockList = () => {
    const renderAttorney = searchAttorneyStockList.length != 0 ? searchAttorneyStockList : search != "" ? [] : attorneyStockList;
    return (
      renderAttorney != null && renderAttorney.length != 0 ? 
        renderAttorney.map((attorneyItem, index) => {
            return (
              <>
              <tr className={`${index % 2 != 0 ? "odd-row" : ""}`}>
                { attorneyItem.attorneys && 
                  attorneyItem.attorneys.length > 0 ? <td>{stockIdExpandedRows.includes(attorneyItem.stock_id) ? 
                  <BiMinus className="select-icon" onClick={() => handleToggleStockIdSublist(attorneyItem.stock_id)}/> : <BiPlus className="select-icon" onClick={() => handleToggleStockIdSublist(attorneyItem.stock_id)}/>}</td> : 
                  <td>{"  "}</td>}
                <td>{formateTime(attorneyItem.meeting_time.split(" ")[0].split("-").join("/"))}</td>
                <td>{attorneyItem.stock_name}</td>
                <td>{attorneyItem.stock_code}</td>
                <td>{attorneyItem.total_count}</td>
                <td>{attorneyItem.confirm_count}</td>
                <td>{attorneyItem.unconfirm_count}</td>
                <td>{attorneyItem.void_count}</td>
                <td>{attorneyItem.confirm_count - attorneyItem.void_count}</td>
              </tr>
              {stockIdExpandedRows.includes(attorneyItem.stock_id) && (
                <tr>
                  <td colSpan="7">
                    <table>
                      <thead>
                        <tr>
                          <th>{toggleAttorneyAllStockId.includes(attorneyItem.stock_id) ? <FaRegCheckSquare onClick={() => handleToggleStockAllAttorney(attorneyItem)}/> : <FaRegSquare onClick={() => handleToggleStockAllAttorney(attorneyItem)}/>}</th>
                          {attorneySubStockSortList.map((content) => {
                            return(
                              <th  onClick={() => { handleAttorneySubStockSort(content.name, index)}}>
                                {content.title}
                                {attorneySubStockSortSelect === content.name ?
                                  renderSortIcon(content.sortKey)
                                  : null}
                              </th>
                            )
                          })}
                          <th>功能</th>
                        </tr>
                      </thead>
                      <tbody>
                      {attorneyItem.attorneys.map((attorney, index) => {
                        return(
                          <tr className={`${index % 2 != 0 ? "odd-row" : ""}`}>
                            {attorney.confirm_count == 0 || attorney.confirm_count - attorney.void_pic == 0 || attorney.shipped == 1 ? 
                              <td>{"  "}</td> : handleConfirmToggle(attorneyItem.stock_id, attorney.attorneys_id) ? 
                              <td><FaRegCheckSquare onClick={() => handelToogleStock(attorneyItem, attorney)}/></td> : <td><FaRegSquare onClick={() => handelToogleStock(attorneyItem, attorney)}/></td>}
                            <td>{attorney.user_login}</td>
                            <td>{attorney.nickname}</td>
                            <td>{padId(attorney.attorneys_id)}</td>
                            <td className={`${attorney.confirm_count - attorney.void_pic == 0 || attorney.shipped == 1 ? "" : "button-text"}`} onClick={() => handelSeprateUpload(attorneyItem, attorney)}>{attorney.confirm_count}</td>
                            <td className="num_colm_small">{attorney.auto_pic}</td>
                            <td className="num_colm_small">{attorney.manual_pic}</td>
                            <td>{attorney.void_pic}</td>
                            <td>{attorney.note}</td>
                            <td><AiFillEdit className="select-icon" onClick={() => {handleShowEditAttorney(attorney)}}/></td>
                          </tr>
                        )
                      })}
                      {toggleStockIds.some(item => item.stockId == attorneyItem.stock_id) ? <tr><td colSpan="9"><button onClick={handleOnShowAttorneyVoid}>退單</button></td></tr> : ""}
                      </tbody>
                    </table>
                  </td>
                </tr>
              )}
              </>
        )}) : ""
    )
  }

  const renderAttorneyIdList = () => {
    const renderAttorney = searchAttorneyList.length != 0 ? searchAttorneyList : search != "" ? [] : stocklist;
    return (
      <div className="attorney-summary-data-form">
        <table className="attorney-summary-table">
          <thead>
          <th>{selectedAllAttorney ? <FaRegCheckSquare className="stock-select-icon"  onClick={() => toggleAllAttorney()}/> : <FaRegSquare className="stock-select-icon"  onClick={() => toggleAllAttorney()}/>} </th>
            {attorneySortItemList.map((content) => {
              return (
                <th
                  onClick={() => { handleAttorneySort(content.name) }}>
                  {content.title}
                  {attorneySortSelect === content.name ? renderSortIcon(content.sortKey) : null}
                </th>
              )
            })}
          </thead>
          <tbody>
          {renderAttorney != null? 
            renderAttorney.map((attorneyItem, index) => {
              const include = selectedAttorney.some((attorney) => attorney.id === attorneyItem.id);
              return (
                <>
                  <tr className={`${index % 2 != 0 ? "odd-row" : ""}`}>
                    <td>{include ? <FaRegCheckSquare onClick={() => handelToggleAttorney(attorneyItem)}/> : <FaRegSquare onClick={() => handelToggleAttorney(attorneyItem)}/>}</td>
                    <td>{attorneyItem.user_login}</td>
                    <td>{attorneyItem.nickname}</td>
                    <td>{attorneyItem.stock_code}</td>
                    <td>{attorneyItem.stock_name}</td>
                    <td className="num_colm_small">{attorneyItem.auto_pic}</td>
                    <td className="num_colm_small">{attorneyItem.manual_pic}</td>
                    <td className="num_colm_small">{attorneyItem.void_pic}</td>
                    <td className="num_colm_small">{parseInt(attorneyItem.auto_pic) + parseInt(attorneyItem.manual_pic) - parseInt(attorneyItem.void_pic)}</td>
                    <td>{attorneyItem.notes}</td>
                    <td>{formateTime(attorneyItem.submit_date.split(" ")[0].split("-").join("/"))}</td>
                    <td className={`${attorneyItem.confirm === 0 ? "not-confirm pointer" : "confirm" }`} onClick={() => handleOnShowAttorneyConfirm(attorneyItem)}>{attorneyItem.confirm === 0 ? "未確認" : "已確認"}</td>
                  </tr>
                </>
              )}) : ""}
          </tbody>
        </table>
        {selectedAttorney.length != 0 ? <><button onClick={() => handleOnShowConfirmSelectedAttorney()}>確認選取項目</button> <button onClick={() => handleOnShowVoidSelectedAttorney()}>刪除選取項目</button></>: ""}
      </div>
    )
  }

  const renderStockIdList = () => {
    return (
      <div className="attorney-summary-data-form">
        <table className="attorney-summary-table">
          <thead>
            <tr>
              <th>{"   "}</th>
              {attorneyStockSortList.map((content) => {
                return (
                  <th onClick={() => { handleAttorneyStockSort(content.name) }}>
                    {content.title}
                    {attorneyStockSortSelect === content.name ?
                        renderSortIcon(content.sortKey)
                      : null}
                  </th>
                )
              })}
            </tr>
          </thead>
          <tbody>
            {renderAttorneyStockList()}
          </tbody>
        </table>
      </div>
    )
  }

  return (
    <>
      <div className="attorney-summary-root">
        <Nav/>
        <div className="attornet-summary-manager-background">
          <div className="manager-title">交單</div>
          <div className="summary-main-display">
            <div className="attorney--type-block">
              <input type="radio" value="attorneyId" name="attorney-display-type" checked={attorneyDisplayType === "attorneyId"} onChange={handleAttorneyDisplayTypeChange} /> 
              <span>核單</span>
              <input type="radio" value="stockId" name="attorney-display-type"  checked={attorneyDisplayType != "attorneyId"} onChange={handleAttorneyDisplayTypeChange} /> 
              <span>退單</span>
            </div>
            
              <div className="attorney-summary-button-bar flex-column align-start">
                <div>
                  <div className="flex-row align-center margin_bottom">
                    <span className="select-input-lable">年份: </span>
                    <Select className="attornet-year-select" menuPlacement="top" value={yearSelected} options={yearOptions} onChange={handleYearChange}/>
                  </div>
                  <div className="flex-row align-center margin_bottom">
                    <span className="select-input-lable">股號或股名: </span>
                    <input className="input-width" value={state.stock} type="text" id="stock" onChange={(e) => handleSearchInputChange(e)}/>
                  </div>
                  {attorneyDisplayType === "attorneyId" && 
                  <div className="flex-row align-center margin_bottom">
                    <span className="select-input-lable">委託截止日前: </span>
                    <input className="input-width" value={state.deadLine} type="date" id="deadLine" onChange={(e) => handleSearchInputChange(e)}/>
                  </div>}
                  <button
                        rounded
                        size="sm"
                        type="submit"
                        className="mr-auto search-button"
                        onClick={handleAttorneySearch}
                      >
                        搜尋
                      </button>
                </div>
                <div className="export-attorney">
                  <button className="new-srock-btn" onClick={handleDownLoadCSV}>
                    匯出委託書
                </button>
                </div>
                
              </div>
              
            {attorneyDisplayType === "attorneyId" ? renderAttorneyIdList() : renderStockIdList()}
          </div>
        </div>
      </div>
      <Loading loading={loading}/>
      <Confirm
        show={showConfirm}
        onHide={handleOnHideConfirm}
        message={"確認要刪除此委託書嗎?"}
        attorneyInfo={padId(selectedAttorneyId)}
        reLoadWindows= {reLoadWindows}
        removeAttorney={true}
        function= {handelDeleteAttorney}/>
      <Confirm
        show={showAttorneyConfirm}
        onHide={handleOnHideAttorneyConfirm}
        message={"確認要確認此委託書嗎?"}
        attorneyInfo={padId(selectedAttorneyId)}
        reLoadWindows= {reLoadWindows}
        function= {handelAttorneyConfirm}/>
      <Confirm
        show={showAttorneyVoid}
        onHide={handleOnHideAttorneyVoid}
        message={"確認要刪除此委託書嗎?"}
        toggleAttorney={toggleStockIds}
        reLoadWindows= {reLoadWindows}
        removeAttorney={true}
        isSeparateRemove={false}
        function= {handleVoidAttorney}/>
      <Confirm
        show={showSeparateAttorneyVoid}
        onHide={handleOnHideSeparateAttorneyVoid}
        message={"確認要刪除此委託書嗎?"}
        toggleAttorney={separateUploadArrorney}
        reLoadWindows= {reLoadWindows}
        removeAttorney={true}
        isSeparateRemove={true}
        function= {handleVoidAttorney}/>
      <Confirm
        show={showEditAttorney}
        onHide={handleOnHideEditAttorney}
        message={"確認要修改此委託書嗎?"}
        editAttorney={editAttorneyData}
        reLoadWindows= {reLoadWindows}
        function= {handleEditAttorney}/>
      <Confirm
        show={showVoidSelectedAttorney}
        onHide={handleOnVoidSelectedAttorney}
        message={"確認要刪除選取的委託書嗎?"}
        attorneys={selectedAttorney}
        reLoadWindows= {reLoadWindows}
        removeAttorney={true}
        function= {handelDeleteAttorney}/>
      <Confirm
        show={showConfirmSelectedAttorney}
        onHide={handleOnHideConfirmSelectedAttorney}
        message={"確認要確認選取的委託書嗎?"}
        attorneys={selectedAttorney}
        reLoadWindows= {reLoadWindows}
        function= {handelAttorneyConfirm}/>
    </>
  )
}

export default connect(
  null,
  {  }
)(AttorneySummaryPage);