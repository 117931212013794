import './App.css';
import { BrowserRouter, Route, Routes } from "react-router-dom"; 
import  { 
  LoginPage, 
  IdManagementPage, 
  StockManagementPage, 
  AttorneyPage, 
  AttorneyConfirmPage, 
  AttorneySummaryPage, 
  ReportPage, 
  ScreenshotDownloadPage,
  SouvenirInventoryPage,
  AboutMePage,
  PrecautionsPage,
  BillboardPage,
  LoveStockPage,
  ShipmentPage,
  UnShipPage,
  ShippedPage,
  RegisterPage,
  ShipConfirmPage }  from "./pages"; 
export default function App() { 
  return ( 
    <BrowserRouter>
      <div className="App" style={{ backgroundImage: "url(./circle.jpg)" }}>
        <Routes>
          <Route exact path="/login" element={<LoginPage/>} />
          <Route exact path="/" element={<StockManagementPage/>} />
          <Route exact path="/idmanagement" element={<IdManagementPage/>} />
          <Route exact path="/attorneyupload" element={<AttorneyPage/>} />
          <Route exact path="/attorneyconfirm" element={<AttorneyConfirmPage/>} />
          <Route exact path="/attorneysummarypage" element={<AttorneySummaryPage/>} />
          <Route exact path="/reportpage" element={<ReportPage/>} />
          <Route exact path="/screenshotdownload" element={<ScreenshotDownloadPage/>} />
          <Route exact path="/souvenirinventory" element={<SouvenirInventoryPage/>} />
          <Route exact path="/aboutme" element={<AboutMePage/>} />
          <Route exact path="/precautions" element={<PrecautionsPage/>} />
          <Route exact path="/billboard" element={<BillboardPage/>} />
          <Route exact path="/lovestock" element={<LoveStockPage/>} />
          <Route exact path="/shipment" element={<ShipmentPage/>} />
          <Route exact path="/unship" element={<UnShipPage/>} />
          <Route exact path="/shipped" element={<ShippedPage/>} />
          <Route exact path="/shipconfirm" element={<ShipConfirmPage/>} />
          <Route exact path="/register" element={<RegisterPage/>} />
        </Routes>
      </div>
    </BrowserRouter>
  ); 
} 
