import { connect } from "react-redux";
import { useState, useEffect } from "react"; 
import Nav from "../../components/Nav";
import InfoPopUp from "../../components/modals/infoPopUp";
import { BiSolidUpArrow, BiSolidDownArrow, BiPlus, BiMinus } from "react-icons/bi";
import { getAttorneyListByStock, getAttorneyListByUser } from "../../actions/attorneyAction";
import "./style.css";
import Select from 'react-select'
import Loading from "../../components/modals/loading";

function ReportPage(props) {
  const companySortList = [
    { name: "meeting_time", sortKey: "down", title: "開會日期" },
    { name: "stock_code", sortKey: "down", title: "股號" },
    { name: "stock_name", sortKey: "down", title: "股名" },
    { name: "total_count", sortKey: "down", title: "總份數" },
    { name: "unconfirm_count", sortKey: "down", title: "未確認份數" },
    { name: "confirm_count", sortKey: "down", title: "已確認份數" },
    { name: "auto_pic", sortKey: "down", title: "收購" },
    { name: "manual_pic", sortKey: "down", title: "不收購" },
    { name: "unshiped_count", sortKey: "down", title: "已確認 - 退單" },
    { name: "void_count", sortKey: "down", title: "退單數" }
  ];

  const customerSortList = [
    { name: "user_group", sortKey: "down", title: "群組" },
    { name: "user_login", sortKey: "down", title: "客戶代號" },
    { name: "nickname", sortKey: "down", title: "客戶姓名" },
    { name: "confirm_count", sortKey: "down", title: "總已確認單數" },
    { name: "shipped_count", sortKey: "down", title: "總出貨數" },
    { name: "acq_count", sortKey: "down", title: "總收購數" },
    { name: "unshiped_count", sortKey: "down", title: "已確認 - 退單" },
    { name: "void_count", sortKey: "down", title: "總退單數" },
    { name: "behalf_price_total", sortKey: "down", title: "總代領費" },
    { name: "acq_price_total", sortKey: "down", title: "總收購費" },
  ];

  const confirmSortList = [
    { name: "user_login", sortKey: "down", title: "客戶代號" },
    { name: "nickname", sortKey: "down", title: "客戶姓名" },
    { name: "stock_code", sortKey: "down", title: "股號" },
    { name: "stock_name", sortKey: "down", title: "股名" },
    { name: "confirm_count", sortKey: "down", title: "已確認份數" }
  ];

  const customerConfirmSortList = [
    { name: "attorney_id", sortKey: "down", title: "單號" },
    { name: "stock_code", sortKey: "down", title: "股號" },
    { name: "stock_name", sortKey: "down", title: "股名" },
    { name: "confirm_count", sortKey: "down", title: "確認份數" },
    { name: "note", sortKey: "down", title: "備註" }
  ];

  const unShipedSortList = [
    { name: "user_login", sortKey: "down", title: "客戶代號" },
    { name: "nickname", sortKey: "down", title: "客戶姓名" },
    { name: "stock_code", sortKey: "down", title: "股號" },
    { name: "stock_name", sortKey: "down", title: "股名" },
    { name: "unshiped_count", sortKey: "down", title: "未出貨份數" }
  ];

  const autoPicSortList = [
    { name: "user_login", sortKey: "down", title: "客戶代號" },
    { name: "nickname", sortKey: "down", title: "客戶姓名" },
    { name: "stock_code", sortKey: "down", title: "股號" },
    { name: "stock_name", sortKey: "down", title: "股名" },
    { name: "auto_pic", sortKey: "down", title: "收購份數" }
  ];

  const manualPicSortList = [
    { name: "user_login", sortKey: "down", title: "客戶代號" },
    { name: "nickname", sortKey: "down", title: "客戶姓名" },
    { name: "stock_code", sortKey: "down", title: "股號" },
    { name: "stock_name", sortKey: "down", title: "股名" },
    { name: "manual_pic", sortKey: "down", title: "不收購份數" }
  ];

  const customerUnshipedSortList = [
    { name: "attorney_id", sortKey: "down", title: "單號" },
    { name: "stock_code", sortKey: "down", title: "股號" },
    { name: "stock_name", sortKey: "down", title: "股名" },
    { name: "unshiped_count", sortKey: "down", title: "未出貨份數" },
    { name: "note", sortKey: "down", title: "備註" }
  ];

  const voidSortList = [
    { name: "user_login", sortKey: "down", title: "客戶代號" },
    { name: "nickname", sortKey: "down", title: "客戶姓名" },
    { name: "attorney_id", sortKey: "down", title: "單號" },
    { name: "stock_code", sortKey: "down", title: "股號" },
    { name: "stock_name", sortKey: "down", title: "股名" },
    { name: "void_pic", sortKey: "down", title: "退單份數" },
    { name: "note", sortKey: "down", title: "備註" }
  ];

  const customerVoidSortList = [
    { name: "attorneys_id", sortKey: "down", title: "單號" },
    { name: "stock_code", sortKey: "down", title: "股號" },
    { name: "stock_name", sortKey: "down", title: "股名" },
    { name: "void_count", sortKey: "down", title: "退單份數" },
    { name: "note", sortKey: "down", title: "備註" }
  ];

  const companyPopUpSortList = [
    { name: "stock_name", sortKey: "down", title: "股名" },
    { name: "attorneys_id", sortKey: "down", title: "交單單號" },
    { name: "submit_date", sortKey: "down", title: "交單時間" },
    { name: "auto_pic", sortKey: "down", title: "電" },
    { name: "manual_pic", sortKey: "down", title: "手" },
    { name: "total_pic", sortKey: "down", title: "總" },
    { name: "note", sortKey: "down", title: "備註" }
  ];

  const [ companyList, setCompanyList ] = useState([]);
  const [ customerList, setCustomerList ] = useState([]);
  const [ sortCompanyList, setSortCompanyList ] = useState(companySortList);
  const [ displayType, setDisplayType ] = useState("company");
  const [ companySortSelect, setCompanySortSelect ] = useState("stock_code");
  const [ confirmSortSelect, setConfirmSortSelect ] = useState("user_login");
  const [ unshipedSortSelect, setUnshipedStockSortSelect ] = useState("user_login");
  const [ voidSortSelect, setVoidSortSelect ] = useState("user_login");
  const [ autoPicSortSelect, setAutoPicSortSelect ] = useState("user_login");
  const [ manualPicSortSelect, setManualPicSortSelect ] = useState("user_login");
  const [ customerSortSelect, setCostomerSortSelect ] = useState("user_login");
  const [ customerConfirmSortSelect, setCostomerConfirmSortSelect ] = useState("attorney_id");
  const [ customerUnshipedSortSelect, setCostomerUnshipedSortSelect ] = useState("attorney_id");
  const [ sortConfirmList, setSortConfirmList ] = useState(confirmSortList);
  const [ sortUnshipedList, setSortUnshipedList ] = useState(unShipedSortList);
  const [ sortVoidList, setSortVoidList ] = useState(voidSortList);
  const [ sortAutoPicList, setSortAotoPicList ] = useState(autoPicSortList);
  const [ sortManualPicList, setSortManualPicList ] = useState(manualPicSortList);
  const [ sortCustomerList, setSortCustomerList ] = useState(customerSortList);
  const [ sortCustomerConfirmList, setSortCustomerConfirmList ] = useState(customerConfirmSortList);
  const [ sortCustomerUnshipedList, setSortCustomerUnshipedList ] = useState(customerUnshipedSortList);
  const [ toggleConfirm, setToggleConfirm ] = useState([]);
  const [ toggleUnshiped, setToggleUnshiped ] = useState([]);
  const [ toggleAutoPic, setToggleAutoPic ] = useState([]);
  const [ toggleManualPic, setToggleManualPic ] = useState([]);
  const [ toggleVoid, setToggleVoid ] = useState([]);
  const [ toggleCustomerConfirm, setToggleCustomerConfirm ] = useState([]);
  const [ toggleCustomerUnshiped, setToggleCustomerUnshiped ] = useState([]);
  const [ toggleCustomerVoid, setToggleCustomerVoid ] = useState([]);
  const [ showInfoPopUp, setShowInfoPopUp ] = useState(false);
  const [ title, setTitle ] = useState("");
  const [ sortList, setSortList ] = useState(null);
  const [ infoData, setInfoData ] = useState(null);
  const [ yearSelected, setYearSelected ] = useState(null);
  const [ yearOptions, setYearOptions ] = useState([]);
  const [ customerConfirmList, setCustomerConfirmList ] = useState([]);
  const [ customerUnshipedList, setCustomerUnshipedList ] = useState([]);
  const [ loading, setLoading ] = useState(false);

  const localUser = JSON.parse(sessionStorage.getItem("user"));
  const currentYear = new Date().getFullYear();

  async function getCompanyAttorneyList() {
    setLoading(true);
    const result = await getAttorneyListByStock(localUser.accessToken, yearSelected.label, "", ""); 
    if(result != null) {
      const updatedCompany = result.map(company => ({
        ...company, // 复制原有的属性
        unshiped_count: company.confirm_count - company.void_count, // 添加新属性
      }));
      setCompanyList(updatedCompany);
      setLoading(false);
    } else {
      setCompanyList([]);
      setLoading(false);
    }
  };

  async function getCustomerAttorneyList() {
    setLoading(true);
    const result = await getAttorneyListByUser(localUser.accessToken, yearSelected.label); 
    if(result != null) {
      const updatedCustomer = result.map(custmoer => ({
        ...custmoer, // 复制原有的属性
        unshiped_count: custmoer.confirm_count - custmoer.void_count, // 添加新属性
      }));
      setCustomerList(updatedCustomer);
      setLoading(false);
    } else {
      setCustomerList([]);
      setLoading(false);
    }
  };

  useEffect(()  => {
    if(yearSelected) {
      if(displayType === "company") {
        getCompanyAttorneyList();
      } else{
        getCustomerAttorneyList();
      }
    }
  }, [displayType, yearSelected]);

  useEffect(() => {
    const options = [];
    
    for (let year = 2023; year <= currentYear + 1; year++) {
      options.push({ value: year, label: (year - 1911).toString() });
    }

    setYearOptions(options);

    // 设置预选值为今年
    const currentYearOption = options.find((option) => option.value === currentYear);
    setYearSelected(currentYearOption);
  }, []);


  const handleYearChange = (selected) => {
    setYearSelected(selected);
  };

  const handleChangeDisplayType = (displayType) => {
    setDisplayType(displayType);
  }

  const handleToggleColum = (company, colum) => {
    if(colum === "confirm_count") {
      let tempToggleConfirm = [...toggleConfirm];
      if(tempToggleConfirm.includes(company.stock_id)) {
        tempToggleConfirm = tempToggleConfirm.filter(stock_id => stock_id != company.stock_id);
      } else {
        tempToggleConfirm.push(company.stock_id);
        const tempToggleUnshiped = toggleUnshiped.filter(stock_id => stock_id != company.stock_id);
        setToggleUnshiped(tempToggleUnshiped);
        const tempToggleVoid = toggleVoid.filter(stock_id => stock_id != company.stock_id);
        setToggleVoid(tempToggleVoid);
        const tempToggleAutoPic = toggleAutoPic.filter(stock_id => stock_id != company.stock_id);
        setToggleAutoPic(tempToggleAutoPic);
        const tempToggleManualPic = toggleManualPic.filter(stock_id => stock_id != company.stock_id);
        setToggleManualPic(tempToggleManualPic);
      }
      setToggleConfirm(tempToggleConfirm);
    } else if(colum === "unshiped_count") {
      let tempToggleUnshiped = [...toggleUnshiped];
      if(tempToggleUnshiped.includes(company.stock_id)) {
        tempToggleUnshiped = tempToggleUnshiped.filter(stock_id => stock_id != company.stock_id);
      } else {
        tempToggleUnshiped.push(company.stock_id);
        const tempToggleConfirm = toggleConfirm.filter(stock_id => stock_id != company.stock_id);
        setToggleConfirm(tempToggleConfirm);
        const tempToggleVoid = toggleVoid.filter(stock_id => stock_id != company.stock_id);
        setToggleVoid(tempToggleVoid);
        const tempToggleAutoPic = toggleAutoPic.filter(stock_id => stock_id != company.stock_id);
        setToggleAutoPic(tempToggleAutoPic);
        const tempToggleManualPic = toggleManualPic.filter(stock_id => stock_id != company.stock_id);
        setToggleManualPic(tempToggleManualPic);
      }
      setToggleUnshiped(tempToggleUnshiped);
    } else if(colum === "void_count") {
      let tempToggleVoid = [...toggleVoid];
      if(tempToggleVoid.includes(company.stock_id)) {
        tempToggleVoid = tempToggleVoid.filter(stock_id => stock_id != company.stock_id);
      } else {
        tempToggleVoid.push(company.stock_id);
        const tempToggleConfirm = toggleConfirm.filter(stock_id => stock_id != company.stock_id);
        setToggleConfirm(tempToggleConfirm);
        const tempToggleUnshiped = toggleUnshiped.filter(stock_id => stock_id != company.stock_id);
        setToggleUnshiped(tempToggleUnshiped);
        const tempToggleAutoPic = toggleAutoPic.filter(stock_id => stock_id != company.stock_id);
        setToggleAutoPic(tempToggleAutoPic);
        const tempToggleManualPic = toggleManualPic.filter(stock_id => stock_id != company.stock_id);
        setToggleManualPic(tempToggleManualPic);
      }
      setToggleVoid(tempToggleVoid);
    } else if(colum === "auto_pic") {
      let tempToggleAutoPic = [...toggleAutoPic];
      if(tempToggleAutoPic.includes(company.stock_id)) {
        tempToggleAutoPic = tempToggleAutoPic.filter(stock_id => stock_id != company.stock_id);
      } else {
        tempToggleAutoPic.push(company.stock_id);
        const tempToggleConfirm = toggleConfirm.filter(stock_id => stock_id != company.stock_id);
        setToggleConfirm(tempToggleConfirm);
        const tempToggleUnshiped = toggleUnshiped.filter(stock_id => stock_id != company.stock_id);
        setToggleUnshiped(tempToggleUnshiped);
        const tempToggleVoid = toggleVoid.filter(stock_id => stock_id != company.stock_id);
        setToggleVoid(tempToggleVoid);
        const tempToggleManualPic = toggleManualPic.filter(stock_id => stock_id != company.stock_id);
        setToggleManualPic(tempToggleManualPic);
      }
      setToggleAutoPic(tempToggleAutoPic);
    } else if(colum === "manual_pic") {
      let tempToggleManualPic = [...toggleManualPic];
      if(tempToggleManualPic.includes(company.stock_id)) {
        tempToggleManualPic = tempToggleManualPic.filter(stock_id => stock_id != company.stock_id);
      } else {
        tempToggleManualPic.push(company.stock_id);
        const tempToggleConfirm = toggleConfirm.filter(stock_id => stock_id != company.stock_id);
        setToggleConfirm(tempToggleConfirm);
        const tempToggleUnshiped = toggleUnshiped.filter(stock_id => stock_id != company.stock_id);
        setToggleUnshiped(tempToggleUnshiped);
        const tempToggleVoid = toggleVoid.filter(stock_id => stock_id != company.stock_id);
        setToggleVoid(tempToggleVoid);
        const tempToggleAutoPic = toggleAutoPic.filter(stock_id => stock_id != company.stock_id);
        setToggleAutoPic(tempToggleAutoPic);
      }
      setToggleManualPic(tempToggleManualPic);
    }
  }

  const handleToggleCustomer = (customer, colum) => {
    if(colum === "confirm_count") {
      let tempToggleCustomerConfirm = [...toggleCustomerConfirm];
      if(tempToggleCustomerConfirm.includes(customer.user_login)) {
        tempToggleCustomerConfirm = tempToggleCustomerConfirm.filter(user_login => user_login != customer.user_login);
      } else {
        tempToggleCustomerConfirm.push(customer.user_login);
        const tempToggleCustomerUnshiped = toggleCustomerUnshiped.filter(user_login => user_login != customer.user_login);
        setToggleCustomerUnshiped(tempToggleCustomerUnshiped);
        const tempToggleCustomerVoid = toggleCustomerVoid.filter(user_login => user_login != customer.user_login);
        setToggleCustomerVoid(tempToggleCustomerVoid);
      }
      setToggleCustomerConfirm(tempToggleCustomerConfirm);
    } else if(colum === "unshiped_count") {
      let tempToggleCustomerUnshiped = [...toggleCustomerUnshiped];
      if(tempToggleCustomerUnshiped.includes(customer.user_login)) {
        tempToggleCustomerUnshiped = tempToggleCustomerUnshiped.filter(user_login => user_login != customer.user_login);
      } else {
        tempToggleCustomerUnshiped.push(customer.user_login);
        const tempToggleCustomerConfirm = toggleCustomerConfirm.filter(user_login => user_login != customer.user_login);
        setToggleCustomerConfirm(tempToggleCustomerConfirm);
        const tempToggleCustomerVoid = toggleCustomerVoid.filter(user_login => user_login != customer.user_login);
        setToggleCustomerVoid(tempToggleCustomerVoid);
      }
      setToggleCustomerUnshiped(tempToggleCustomerUnshiped);
    } else if(colum === "void_count") {
      let tempToggleCustomerVoid = [...toggleCustomerVoid];
      if(tempToggleCustomerVoid.includes(customer.user_login)) {
        tempToggleCustomerVoid = tempToggleCustomerVoid.filter(user_login => user_login != customer.user_login);
      } else {
        tempToggleCustomerVoid.push(customer.user_login);
        const tempToggleCustomerConfirm = toggleCustomerConfirm.filter(user_login => user_login != customer.user_login);
        setToggleCustomerConfirm(tempToggleCustomerConfirm);
        const tempToggleCustomerUnshiped = toggleCustomerUnshiped.filter(user_login => user_login != customer.user_login);
        setToggleCustomerUnshiped(tempToggleCustomerUnshiped);
      }
      setToggleCustomerVoid(tempToggleCustomerVoid);
    }
  }

  const handleCompanySort = (sortKey) => {
    setCompanySortSelect(sortKey);
    handleCompanySortChange(sortKey)
  };

  const handleCompanySortChange = (sortKey) => {
    let nextSort = "";
    let tempSortedList = [...companyList];
    let tempSortItemList = [...sortCompanyList];
    tempSortItemList.map((content) => {
      if (content.name === sortKey) {
        nextSort = content.sortKey === "down" ? "up" : "down";
        sortByKey(tempSortedList, sortKey, content.sortKey, "companySort");
        content.sortKey = nextSort;
        return content.sortKey
      }
      return null
    })
    setSortCompanyList(tempSortItemList);
    setCompanyList(tempSortedList);
  };

  const handleConfirmSort = (sortKey, index) => {
    setConfirmSortSelect(sortKey);
    handleConfirmSortChange(sortKey, index);
    
  };

  const handleConfirmSortChange = (sortKey, index) => {
    let nextSort = "";
    let tempSortedList = [...companyList];
    let tempSortItemList = [...sortConfirmList];
    tempSortItemList.map((content) => {
      if (content.name === sortKey) {
        nextSort = content.sortKey === "down" ? "up" : "down";
        sortByKey(tempSortedList[index].attorneys, sortKey, content.sortKey, "confirmSort");
        content.sortKey = nextSort;
        return content.sortKey
      }
      return null
    })
    setSortConfirmList(tempSortItemList);
    setCompanyList(tempSortedList);
  };

  const handleUnshipedSort = (sortKey, index) => {
    setUnshipedStockSortSelect(sortKey);
    handleUnshipedSortChange(sortKey, index);
  };

  const handleUnshipedSortChange = (sortKey, index) => {
    let nextSort = "";
    let tempSortedList = [...companyList];
    let tempSortItemList = [...sortUnshipedList];
    tempSortItemList.map((content) => {
      if (content.name === sortKey) {
        nextSort = content.sortKey === "down" ? "up" : "down";
        sortByKey(tempSortedList[index].attorneys, sortKey, content.sortKey, "unshipedSort");
        content.sortKey = nextSort;
        return content.sortKey
      }
      return null
    })
    setSortUnshipedList(tempSortItemList);
    setCompanyList(tempSortedList);
  };

  const handleVoidSort = (sortKey, index) => {
    setVoidSortSelect(sortKey);
    handleVoidSortChange(sortKey, index);
  };

  const handleVoidSortChange = (sortKey, index) => {
    let nextSort = "";
    let tempSortedList = [...companyList];
    let tempSortItemList = [...sortVoidList];
    tempSortItemList.map((content) => {
      if (content.name === sortKey) {
        nextSort = content.sortKey === "down" ? "up" : "down";
        sortByKey(tempSortedList[index].void_attorneys, sortKey, content.sortKey, "voidSort");
        content.sortKey = nextSort;
        return content.sortKey
      }
      return null
    })
    setSortVoidList(tempSortItemList);
    setCompanyList(tempSortedList);
  };

  const handleManualPicSort = (sortKey, index) => {
    setManualPicSortSelect(sortKey);
    handleManualPicSortChange(sortKey, index);
  };

  const handleManualPicSortChange = (sortKey, index) => {
    let nextSort = "";
    let tempSortedList = [...companyList];
    let tempSortItemList = [...sortManualPicList];
    tempSortItemList.map((content) => {
      if (content.name === sortKey) {
        nextSort = content.sortKey === "down" ? "up" : "down";
        sortByKey(tempSortedList[index].attorneys, sortKey, content.sortKey, "manualPic");
        content.sortKey = nextSort;
        return content.sortKey
      }
      return null
    })
    setSortManualPicList(tempSortItemList);
    setCompanyList(tempSortedList);
  };

  const handleAutoPicSort = (sortKey, index) => {
    setAutoPicSortSelect(sortKey);
    handleAutoPicSortChange(sortKey, index);
  };

  const handleAutoPicSortChange = (sortKey, index) => {
    let nextSort = "";
    let tempSortedList = [...companyList];
    let tempSortItemList = [...sortAutoPicList];
    tempSortItemList.map((content) => {
      if (content.name === sortKey) {
        nextSort = content.sortKey === "down" ? "up" : "down";
        sortByKey(tempSortedList[index].attorneys, sortKey, content.sortKey, "autoPic");
        content.sortKey = nextSort;
        return content.sortKey
      }
      return null
    })
    setSortAotoPicList(tempSortItemList);
    setCompanyList(tempSortedList);
  };

  const handleCustomerSort = (sortKey) => {
    setCostomerSortSelect(sortKey);
    handleCustomerSortChange(sortKey)
  };

  const handleCustomerSortChange = (sortKey) => {
    let nextSort = "";
    let tempSortedList = [...customerList];
    let tempSortItemList = [...sortCustomerList];
    tempSortItemList.map((content) => {
      if (content.name === sortKey) {
        nextSort = content.sortKey === "down" ? "up" : "down";
        sortByKey(tempSortedList, sortKey, content.sortKey, "customerSort");
        content.sortKey = nextSort;
        return content.sortKey
      }
      return null
    })
    setSortCustomerList(tempSortItemList);
    setCustomerList(tempSortedList);
  };

  const handleCustomerConfirmSort = (sortKey, index) => {
    setCostomerConfirmSortSelect(sortKey);
    handleCustomerConfirmSortChange(sortKey, index)
  };

  const handleCustomerConfirmSortChange = (sortKey, index) => {
    let nextSort = "";
    let tempSortedList = [...customerList];
    let tempSortItemList = [...sortCustomerConfirmList];
    tempSortItemList.map((content) => {
      if (content.name === sortKey) {
        nextSort = content.sortKey === "down" ? "up" : "down";
        sortByKey(tempSortedList[index].attorneys, sortKey, content.sortKey, "customerConfirmSort");
        content.sortKey = nextSort;
        return content.sortKey
      }
      return null
    })
    setSortCustomerConfirmList(tempSortItemList);
    setCustomerList(tempSortedList);
  };

  const handleCustomerUnshipedSort = (sortKey, index) => {
    setCostomerUnshipedSortSelect(sortKey);
    handleCustomerUnshipedSortChange(sortKey, index)
  };

  const handleCustomerUnshipedSortChange = (sortKey, index) => {
    let nextSort = "";
    let tempSortedList = [...customerList];
    let tempSortItemList = [...sortCustomerUnshipedList];
    tempSortItemList.map((content) => {
      if (content.name === sortKey) {
        nextSort = content.sortKey === "down" ? "up" : "down";
        sortByKey(tempSortedList[index].attorneys, sortKey, content.sortKey, "customerUnshipedSort");
        content.sortKey = nextSort;
        return content.sortKey
      }
      return null
    })
    setSortCustomerUnshipedList(tempSortItemList);
    setCustomerList(tempSortedList);
  };

  const sortByKey = (array, sortKey, sortRule, sortListType) => {
    if (sortRule === "down") {
      if(sortKey == "unshiped_count" && sortListType === "unshipedSort") {
        return array.sort((a, b) => {
          return ((a["confirm_count"] - parseInt(a["void_pic"]) < b["confirm_count"] - parseInt(b["void_pic"])) ? -1 : ((a["confirm_count"] - parseInt(a["void_pic"]) > b["confirm_count"] - parseInt(b["void_pic"])) ? 1 : 0));
        });
      } else if(sortKey == "unshiped_count" && sortListType === "customerUnshipedSort") {
        return array.sort((a, b) => {
          return ((a["confirm_count"] - parseInt(a["void_count"]) < b["confirm_count"] - parseInt(b["void_count"])) ? -1 : ((a["confirm_count"] - parseInt(a["void_count"]) > b["confirm_count"] - parseInt(b["void_count"])) ? 1 : 0));
        });
      } else if(sortKey == "auto_pic" || sortKey == "manual_pic") {
        return array.sort((a, b) => {
          return ((parseInt(a[sortKey]) < parseInt(b[sortKey])) ? -1 : ((parseInt(a[sortKey]) > parseInt(b[sortKey])) ? 1 : 0));
        });
      } else {
        return array.sort((a, b) => {
          return ((a[sortKey] < b[sortKey]) ? -1 : ((a[sortKey] > b[sortKey]) ? 1 : 0));
        });
      }
      
    }
    else {
      if(sortKey == "unshiped_count" && sortListType === "unshipedSort") {
        return array.sort((a, b) => {
          return ((a["confirm_count"] - parseInt(a["void_pic"]) > b["confirm_count"] - parseInt(b["void_pic"])) ? -1 : ((a["confirm_count"] - parseInt(a["void_pic"]) < b["confirm_count"] - parseInt(b["void_pic"])) ? 1 : 0));
        });
      } else if(sortKey == "unshiped_count" && sortListType === "customerUnshipedSort") {
        return array.sort((a, b) => {
          return ((a["confirm_count"] - parseInt(a["void_count"]) > b["confirm_count"] - parseInt(b["void_count"])) ? -1 : ((a["confirm_count"] - parseInt(a["void_count"]) < b["confirm_count"] - parseInt(b["void_count"])) ? 1 : 0));
        });
      } else if(sortKey == "auto_pic" || sortKey == "manual_pic") {
        return array.sort((a, b) => {
          return ((parseInt(a[sortKey]) > parseInt(b[sortKey])) ? -1 : ((parseInt(a[sortKey]) < parseInt(b[sortKey])) ? 1 : 0));
        });
      } else {
        return array.sort((a, b) => {
          return ((a[sortKey] > b[sortKey]) ? -1 : ((a[sortKey] < b[sortKey]) ? 1 : 0));
        });
      }
      
    }
  };

  function padId(id) {
    const strId = String(id);
    const paddingLength = 8 - strId.length;
    
    if (paddingLength <= 0) {
      return strId;
    }
  
    const paddingZeros = '0'.repeat(paddingLength);
    return paddingZeros + strId;
  };

  function formateTime(time) {
    const originalDate = new Date(time);

    // 提取年、月、日
    const year = originalDate.getFullYear().toString().slice(2); // 提取后两位
    const month = (originalDate.getMonth() + 1).toString().padStart(2, '0'); // 月份从0开始
    const day = originalDate.getDate().toString().padStart(2, '0');

    // 格式化为 "YY/MM/DD" 的字符串
    const formattedDateString = `${year}/${month}/${day}`;
    if(time == "0000/00/00") {
      return("00/00/00");
    } else {
      return(formattedDateString);
    }
  };

  const handleOnHideInfoPopUp = () => {
    setShowInfoPopUp(false);
  };

  function handleShowInfo(title, sortList, data) {
    setShowInfoPopUp(true);
    setTitle(title);
    setSortList(sortList);
    setInfoData(data);
  }

  const renderSortIcon = (sortKey) => {
    return sortKey === "down" ? <BiSolidDownArrow className="sort-icon" /> : <BiSolidUpArrow className="sort-icon" />
  };

  function renderCompanyDataList() {
    return (
      companyList.length > 0 ? companyList.map((company, index) => {
        return(
          <>
          <tr className={`${index % 2 != 0 ? "odd-row" : ""}`}>
            <td>{formateTime(company.meeting_time.split(" ")[0].split("-").join("/"))}</td>
            <td>{company.stock_code}</td>
            <td>{company.stock_name}</td>
            <td>{company.total_count}</td>
            <td>{company.unconfirm_count}</td>
            <td className="pointer button-text" onClick={() => handleToggleColum(company, "confirm_count")}>{company.confirm_count}</td>
            <td className='num_colm pointer button-text' onClick={() => handleToggleColum(company, "auto_pic")}>{company.auto_pic}</td>
            <td className='num_colm pointer button-text' onClick={() => handleToggleColum(company, "manual_pic")}>{company.manual_pic}</td>
            <td className="pointer button-text" onClick={() => handleToggleColum(company, "unshiped_count")}>{company.unshiped_count}</td>
            <td className="pointer button-text" onClick={() => handleToggleColum(company, "void_count")}>{company.void_count}</td>
          </tr>
          {toggleConfirm.includes(company.stock_id) && (
            <tr>
              <td colSpan="11">
                <table>
                  <thead>
                    {confirmSortList.map((content) => {
                      return(
                        <th onClick={() => { handleConfirmSort(content.name, index) }} className="pointer">
                          {content.title}
                          {confirmSortSelect === content.name ?
                            renderSortIcon(content.sortKey)
                            : null}
                        </th>
                      )
                    })}
                  </thead>
                  <tbody>
                  {company.attorneys.map((attorney,index) => {
                    return(
                      <tr className={`${index % 2 != 0 ? "odd-row" : ""}`}>
                        <td className="pointer button-text" onClick={() => handleShowInfo("已確認委託書", companyPopUpSortList, attorney)}>{attorney.user_login}</td>
                        <td>{attorney.nickname}</td>
                        <td>{attorney.stock_code}</td>
                        <td>{attorney.stock_name}</td>
                        <td>{attorney.confirm_count}</td>
                      </tr>
                    )
                  })}
                  </tbody>
                </table>
              </td>
            </tr>
          )}
          {toggleUnshiped.includes(company.stock_id) && (
            <tr>
              <td colSpan="11">
                <table>
                  <thead>
                    {unShipedSortList.map((content) => {
                      return(
                        <th onClick={() => { handleUnshipedSort(content.name, index) }} className="pointer">
                          {content.title}
                          {unshipedSortSelect === content.name ?
                            renderSortIcon(content.sortKey)
                            : null}
                        </th>
                      )
                    })}
                  </thead>
                  <tbody>
                  {company.attorneys.map((attorney,index) => {
                    return(
                      <tr className={`${index % 2 != 0 ? "odd-row" : ""}`}>
                        <td className="pointer button-text" onClick={() => handleShowInfo("未出貨委託書", companyPopUpSortList, attorney)}>{attorney.user_login}</td>
                        <td>{attorney.nickname}</td>
                        <td>{attorney.stock_code}</td>
                        <td>{attorney.stock_name}</td>
                        <td>{attorney.confirm_count - parseInt(attorney.void_pic)}</td>
                      </tr>
                    )
                  })}
                  </tbody>
                </table>
              </td>
            </tr>
          )}
          {toggleVoid.includes(company.stock_id) && (
            <tr>
              <td colSpan="11">
                <table>
                  <thead>
                    {voidSortList.map((content) => {
                      return(
                        <th onClick={() => { handleVoidSort(content.name, index) }} className="pointer">
                          {content.title}
                          {voidSortSelect === content.name ?
                            renderSortIcon(content.sortKey)
                            : null}
                        </th>
                      )
                    })}
                  </thead>
                  <tbody>
                  {company.void_attorneys.map((attorney,index) => {
                    return(
                      <tr className={`${index % 2 != 0 ? "odd-row" : ""}`}>
                        <td>{attorney.user_login}</td>
                        <td>{attorney.nickname}</td>
                        <td>{padId(attorney.attorney_id)}</td>
                        <td>{attorney.stock_code}</td>
                        <td>{attorney.stock_name}</td>
                        <td>{attorney.void_amount}</td>
                        <td>{attorney.void_note}</td>
                      </tr>
                    )
                  })}
                  </tbody>
                </table>
              </td>
            </tr>
          )}
          {toggleAutoPic.includes(company.stock_id) && (
            <tr>
              <td colSpan="11">
                <table>
                  <thead>
                    {autoPicSortList.map((content) => {
                      return(
                        <th onClick={() => { handleAutoPicSort(content.name, index) }} className="pointer">
                          {content.title}
                          {autoPicSortSelect === content.name ?
                            renderSortIcon(content.sortKey)
                            : null}
                        </th>
                      )
                    })}
                  </thead>
                  <tbody>
                  {company.attorneys.filter(attorney => attorney.auto_pic != 0).map((attorney,index) => {
                    return(
                      <tr className={`${index % 2 != 0 ? "odd-row" : ""}`}>
                        <td>{attorney.user_login}</td>
                        <td>{attorney.nickname}</td>
                        <td>{attorney.stock_code}</td>
                        <td>{attorney.stock_name}</td>
                        <td>{attorney.auto_pic}</td>
                      </tr>
                    )
                  })}
                  </tbody>
                </table>
              </td>
            </tr>
          )}
          {toggleManualPic.includes(company.stock_id) && (
            <tr>
              <td colSpan="11">
                <table>
                  <thead>
                    {manualPicSortList.map((content) => {
                      return(
                        <th onClick={() => { handleManualPicSort(content.name, index) }} className="pointer">
                          {content.title}
                          {manualPicSortSelect === content.name ?
                            renderSortIcon(content.sortKey)
                            : null}
                        </th>
                      )
                    })}
                  </thead>
                  <tbody>
                  {company.attorneys.filter(attorney => attorney.manual_pic != 0).map((attorney,index) => {
                    return(
                      <tr className={`${index % 2 != 0 ? "odd-row" : ""}`}>
                        <td>{attorney.user_login}</td>
                        <td>{attorney.nickname}</td>
                        <td>{attorney.stock_code}</td>
                        <td>{attorney.stock_name}</td>
                        <td>{attorney.manual_pic}</td>
                      </tr>
                    )
                  })}
                  </tbody>
                </table>
              </td>
            </tr>
          )}
          </>
        )
      }) : ""
    )
  };

  const renderCompanyList = () => {
     return(
      <div className="company-data-form">
        <table>
          <thead>
          {companySortList.map((content) => {
            return (
            <th onClick={() => { handleCompanySort(content.name) }}>
              {content.title}
              {companySortSelect === content.name ?
                renderSortIcon(content.sortKey)
                : null}
            </th>
            )
          })}
          </thead>
          <tbody>
            {companyList.length > 0 ? renderCompanyDataList() : ""}
          </tbody>
        </table>
      </div>
     )
  };

  function renderCustomerDataList() {
    return (
      customerList.length > 0 ? customerList.map((customer, index) => {
        const renderConfirmList = customerConfirmList.length != 0 ? customerConfirmList : customer.attorneys;
        const renderUnshipedList = customerUnshipedList.length != 0 ? customerUnshipedList : customer.attorneys;
        return(
          <>
            <tr>
              <td>{customer.user_group}</td>
              <td>{customer.user_login}</td>
              <td>{customer.nickname}</td>
              <td className="pointer button-text" onClick={() => handleToggleCustomer(customer, "confirm_count")}>{customer.confirm_count}</td>
              <td>{customer.shipped_count}</td>
              <td>{customer.acq_count}</td>
              <td className="pointer button-text" onClick={() => handleToggleCustomer(customer, "unshiped_count")}>{customer.unshiped_count}</td>
              <td className="pointer button-text" onClick={() => handleToggleCustomer(customer, "void_count")}>{customer.void_count}</td>
              <td>{customer.behalf_price_total}</td>
              <td>{customer.acq_price_total}</td>
            </tr>
            {toggleCustomerConfirm.includes(customer.user_login) && (
              <tr>
                <td colSpan="9">
                  <table>
                    <thead>
                      {customerConfirmSortList.map((content) => {
                        return (
                          <th className="pointer" onClick={() => {handleCustomerConfirmSort(content.name, index)}}>
                            {content.title}
                            {customerConfirmSortSelect === content.name ?
                              renderSortIcon(content.sortKey)
                              : null}
                          </th>
                        )
                      })}
                    </thead>
                    <tbody>
                      {renderConfirmList.map((attorney) => {
                        return(
                          <tr>
                            <td>{padId(attorney.attorney_id)}</td>
                            <td>{attorney.stock_code}</td>
                            <td>{attorney.stock_name}</td>
                            <td>{attorney.confirm_count}</td>
                            <td>{attorney.note}</td>
                          </tr>
                        )
                      })}
                    </tbody>
                  </table>
                </td>
              </tr>
            )}
            {toggleCustomerUnshiped.includes(customer.user_login) && (
              <tr>
                <td colSpan="9">
                  <table>
                    <thead>
                      {customerUnshipedSortList.map((content) => {
                        return (
                          <th className="pointer" onClick={() => {handleCustomerUnshipedSort(content.name, index)}}>
                            {content.title}
                            {customerUnshipedSortSelect === content.name ?
                              renderSortIcon(content.sortKey)
                              : null}
                          </th>
                        )
                      })}
                    </thead>
                    <tbody>
                      {renderUnshipedList.map((attorney) => {
                        return(
                          <tr>
                            <td>{padId(attorney.attorney_id)}</td>
                            <td>{attorney.stock_code}</td>
                            <td>{attorney.stock_name}</td>
                            <td>{attorney.confirm_count - attorney.void_count}</td>
                            <td>{attorney.note}</td>
                          </tr>
                        )
                      })}
                    </tbody>
                  </table>
                </td>
              </tr>
            )}
            {toggleCustomerVoid.includes(customer.user_login) && (
              <tr>
                <td colSpan="9">
                  <table>
                    <thead>
                      {customerVoidSortList.map((content) => {
                        return (
                          <th>
                            {content.title}
                            {confirmSortSelect === content.name ?
                              renderSortIcon(content.sortKey)
                              : null}
                          </th>
                        )
                      })}
                    </thead>
                    <tbody>
                      {customer.attorneys.filter(attorney => attorney.void_count != 0).map((attorney) => {
                        return(
                          <tr>
                            <td>{padId(attorney.attorney_id)}</td>
                            <td>{attorney.stock_code}</td>
                            <td>{attorney.stock_name}</td>
                            <td>{attorney.void_count}</td>
                            <td>{attorney.note}</td>
                          </tr>
                        )
                      })}
                    </tbody>
                  </table>
                </td>
              </tr>
            )}
          </>
        )
      }
    ) : "")
  };

  const renderCustomerList = () => {
    return(
      <div className="company-data-form">
        <table>
          <thead>
          {customerSortList.map((content) => {
            return (
            <th className="pointer" onClick={() => {handleCustomerSort(content.name)}}>
              {content.title}
              {customerSortSelect === content.name ?
                renderSortIcon(content.sortKey)
              : null}
            </th>
            )
          })}
          </thead>
          <tbody>
            {customerList.length > 0 ? renderCustomerDataList() : ""}
          </tbody>
        </table>
      </div>
    )
  };

  return (
    <>
      <div className="report-root">
        <Nav/>
        <div className="manager-background">
          <div className="manager-title">總表</div>
          <div className="main-display">
            <div className="display-type-block">
              <input type="radio" value="attorneyId" name="attorney-display-type" checked={displayType === "company"} onClick={() => handleChangeDisplayType("company")}/> 
              <span>公司</span>
              <input type="radio" value="stockId" name="attorney-display-type" checked={displayType === "custumer"} onClick={() => handleChangeDisplayType("custumer")}/> 
              <span>客戶</span>
            </div>
            <div className="year-select-block">
              <Select value={yearSelected} options={yearOptions} onChange={handleYearChange}/>
            </div>
            {displayType === "company" ? renderCompanyList() : renderCustomerList()}
          </div>
        </div>
      </div>
      <InfoPopUp show={showInfoPopUp} onHide={handleOnHideInfoPopUp} title={title} sortList={sortList} displayType={displayType} data={infoData}/>
      <Loading loading={loading}/>
    </>
  )
}

export default connect(
  null,
  {  }
)(ReportPage);