const initialState = {
  user: null,
  account: null,
  phyIdList: null,
};

const admin = (state = initialState, action) => {
    switch (action.type) {
      case "SET_USER":
        return {...state,
          user: action.payload,
        };
      case "SET_ACCOUNT":
        return {...state,
          account: action.payload,
        };
      case "SET_PHYIDLIST":
        return {...state,
          phyIdList: action.payload,
        };
      default:
        return (state);
    }
};

export default admin;