import { S_1_0 } from "../configs/config";


export const getShipmentList = async (year, user_id, token) => {
  try {
    const response = await fetch(`${S_1_0}/shipment/list/?year=${year}&user_id=${user_id}`, {
      method: "GET",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        "Authorization": "Bearer " + token,
      },
    });
    if (response.ok) {
      const shipment = await response.json();
      return shipment;
    } else {
      return null;
    }
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
};

export const getUnshippedList = async (year, user_login, token) => {
  try {
    const response = await fetch(`${S_1_0}/shipment/listunshipped/?year=${year}&user_login=${user_login}`, {
      method: "GET",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        "Authorization": "Bearer " + token,
      },
    });
    if (response.ok) {
      const shipment = await response.json();
      return shipment;
    } else {
      return null;
    }
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
};

export const addShipment = async (shipped_datas, user_login, year, token) => {
  try {
    const response = await fetch(`${S_1_0}/shipment/add/`, {
      method: "POST",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        "Authorization": "Bearer " + token,
      },
      body: JSON.stringify({
        user_login: user_login,
        shipped_datas: shipped_datas
      }),
    });
    if (response.ok) {
      const unshippedList = getUnshippedList(year, user_login, token);
      return unshippedList;
    } else {
      response.json().then(responseJson => {
        if(responseJson.code =="4011") {
          window.alert("含有已出貨委託書");
          return false;
        }
      });
    }
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
};

export const getShippedList = async (user_login, token) => {
  try {
    const response = await fetch(`${S_1_0}/shipment/listshipped/?user_login=${user_login}`, {
      method: "GET",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        "Authorization": "Bearer " + token,
      },
    });
    if (response.ok) {
      const shipment = await response.json();
      return shipment;
    } else {
      return null;
    }
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
};