import React, { Component } from 'react';
import { connect } from "react-redux";
import Nav from "../../components/Nav"
import "./style.css";
import { getStockBeforeDeadline } from "../../actions/stockAction";
import { getStockPicCount, uploadScreenShot, uploadAttorney } from "../../actions/attorneyAction";
import { useNavigate } from "react-router-dom"; 
import Confirm from "../../components/modals/confirm";
import UploadScreenShot from "../../components/modals/uploadScreenShot.js";
import Loading from "../../components/modals/loading";
import { useState, useEffect } from "react"; 
import Select from 'react-select'
import { useDispatch, useSelector } from "react-redux/";
import { FaRegSquare, FaRegCheckSquare } from "react-icons/fa";
import { BiSolidUpArrow, BiSolidDownArrow } from "react-icons/bi";
import { MDBInputGroup } from "mdbreact";

function AttorneyPage(props) {
  const currentYear = new Date().getFullYear();
  const longSortItemList = [
    { name: "stock_code", sortKey: "down", title: "股號" },
    { name: "stock_name", sortKey: "down", title: "股名" },
    { name: "gift_item", sortKey: "down", title: "紀念品" },
    { name: "acq_price", sortKey: "down", title: "收購價" },
    { name: "auto_upload", sortKey: "down", title: "收購" },
    { name: "menul_upload", sortKey: "down", title: "不收購" },
    { name: "total_upload", sortKey: "down", title: "總" },
    { name: "deadline", sortKey: "down", title: "交單截止日" },
    { name: "note", sortKey: "down", title: "委託條件" },
    { name: "notes", sortKey: "down", title: "備註" },
  ];
  const localUser = JSON.parse(sessionStorage.getItem("user"));
  const [ sortSelect, setSortSelect ] = useState("stock_code");
  const [ sortItemList, setSortItemList ] = useState(longSortItemList);
  const [ sortedList, setSortedList ] = useState([]);
  const [ selectAcqAll, setSelectAcqAll ] = useState(false);
  const [ showUploadScreenShot, setShowUploadScreenShot] = useState(false);
  const [ selectedStockCode, setSelectedStockCode ] = useState("");
  const [ manualUploadCount, setManualUploadCount ] = useState([]);
  const [ selectedAttorney, setSelectedAttorney ] = useState([]);
  const [ loading, setLoading ] = useState(false);
  const [ yearSelected, setYearSelected ] = useState(null);
  const [ yearOptions, setYearOptions ] = useState([]);
  const [ uploadResult, setUploadResult ] = useState(null);
  const [ showUploadResultConfirm, setShowUploadResultConfirm ] = useState(false);
  const [ selectAllAttorney, setSelectAllAttorney ] = useState(false);
  const [ searchText, setSearchText ] = useState("");
  const [ search, setSearch ] = useState("");
  const [ searchSortedList, setSearchSortedList ] = useState([]);
  
  const dispatch = useDispatch();
  const navigate  = useNavigate(); 

  async function getStockList() {
    setLoading(true);
    const result = await getStockBeforeDeadline(localUser.id, localUser.user_login, localUser.accessToken);
    if(result != null) {
      const updatedStock = result.map(stock => ({
        ...stock, // 复制原有的属性
        total_upload: stock.auto_count, // 添加新属性
        notes: "",
      }));
      setSortedList(updatedStock);
      setLoading(false);
    }    
  }

  useEffect(() => {
    if (!localUser) {
      navigate("/login")
    }
  }, []);

  useEffect(() => {
    if(localUser && yearSelected) {
      getStockList();
    }
  }, [yearSelected]);

  useEffect(() => {
    const options = [];
    
    for (let year = 2023; year <= currentYear + 1; year++) {
      options.push({ value: year, label: (year - 1911).toString() });
    }

    setYearOptions(options);

    // 设置预选值为今年
    const currentYearOption = options.find((option) => option.value === currentYear);
    setYearSelected(currentYearOption);
  }, []);

  useEffect(() => {
    if(search != "") {
      let tempSortedList;
      if(sortedList != null) {
        tempSortedList = sortedList.filter(stock => stock.stock_code.includes(search) || stock.stock_name.includes(search) || stock.gift_item.includes(search));
        setSearchSortedList(tempSortedList);
      }
    } else {
      setSearchSortedList([]);
    }
  }, [search, sortedList])

  const handleYearChange = (selected) => {
    setYearSelected(selected);
  };

  const reLoadWindows = () => {
    window.location.reload();
  }

  const handleSort = (sortKey) => {
    if(sortKey != "acq_all") {
      setSortSelect(sortKey);
      handleSortChange(sortKey);
    }
    
  };

  const handleSortChange = (sortKey) => {
    let nextSort = "";
    // let tempSortedList = [...sortedList];
    let tempSortItemList = [...sortItemList];
    let tempSortedList = [];
    if(searchSortedList.length != 0) {
      tempSortedList = [...searchSortedList];
    } else {
      tempSortedList = [...sortedList];
    }
    tempSortItemList.map((content) => {
      if (content.name === sortKey) {
        nextSort = content.sortKey === "down" ? "up" : "down";
        sortByKey(tempSortedList, sortKey, content.sortKey);
        content.sortKey = nextSort;
        return content.sortKey
      }
      return null
    })
    setSortItemList(tempSortItemList);
    if(searchSortedList.length != 0) {
      setSearchSortedList(tempSortedList);
    } else {
      setSortedList(tempSortedList);
    }
  };

  async function handelUploadScreenShot(file) {
    setLoading(true);
    const result = await uploadScreenShot(localUser.user_login, selectedStockCode, file, yearSelected, localUser.accessToken);
    if(result) {
      handleOnHideUploadScreenShot();
      setLoading(false);
      const jsonObject = JSON.parse(result);
      setUploadResult(jsonObject);
      setShowUploadResultConfirm(true);
    } else {
      setLoading(false);
      window.alert("file upload fail");
    }
  }

  const handleOnHideUploadResultConfirm = () => {
    setShowUploadResultConfirm(false);
    getStockList();
    // reLoadWindows();
  }

  const sortByKey = (array, sortKey, sortRule) => {
    if (sortRule === "down") {
      return array.sort((a, b) => {
        return ((a[sortKey] < b[sortKey]) ? -1 : ((a[sortKey] > b[sortKey]) ? 1 : 0));
      });
    }
    else {
      return array.sort((a, b) => {
        return ((a[sortKey] > b[sortKey]) ? -1 : ((a[sortKey] < b[sortKey]) ? 1 : 0));
      });
    }
  };

  const renderSortIcon = (sortKey) => {
    return sortKey === "down" ? <BiSolidDownArrow className="sort-icon" /> : <BiSolidUpArrow className="sort-icon" />
  };

  const handelAcqAll = () => {
    const tempSortList = [...sortedList];
    const tempSelectedAttorney = [...selectedAttorney];
    tempSortList.forEach(element => {
      const selectedIndex = selectedAttorney.findIndex(item => item.stock_code === element.stock_code);
      if(selectAcqAll) {
        if(element.auto_count !== 0 || element.manual_count !== 0) {
          element.acq_all = false;
          if(selectedIndex != -1) {
            tempSelectedAttorney[selectedIndex].acq_all = "0";
          }
        }
      } else {
        if(element.auto_count !== 0 || element.manual_count !== 0) {
          element.acq_all = true;
          if(selectedIndex != -1) {
            tempSelectedAttorney[selectedIndex].acq_all = "1";
          }
        }
        
      }
    });
    setSelectedAttorney(tempSelectedAttorney);
    setSortedList(tempSortList);
    setSelectAcqAll(!selectAcqAll);
  };

  const handelShowUploadScreenShot = (stock_code) => {
    setSelectedStockCode(stock_code)
    setShowUploadScreenShot(true);
  };

  const handleOnHideUploadScreenShot = () => {
    setShowUploadScreenShot(false);
  };

  const handelToggleAcqAll = (attorneyItem) => {
    const tempSortedList = [... sortedList];
    const index = sortedList.findIndex(item => item.stock_code === attorneyItem.stock_code);
    if(attorneyItem.auto_count !== 0 || attorneyItem.manual_count !== 0) {
      tempSortedList[index].acq_all = !tempSortedList[index].acq_all;
    }

    setSortedList(tempSortedList);
    if(selectAcqAll) {
      setSelectAcqAll(false);
    }
    const tempSelectedAttorney = [...selectedAttorney];
    const selectedIndex = selectedAttorney.findIndex(item => item.stock_code === attorneyItem.stock_code);
    if(selectedIndex != -1 && (attorneyItem.auto_count !== 0 || attorneyItem.manual_count !== 0)) {
      if(tempSelectedAttorney[selectedIndex].acq_all == "1") {
        tempSelectedAttorney[selectedIndex].acq_all = "0";
      } else {
        tempSelectedAttorney[selectedIndex].acq_all = "1";
      }
    }
    setSelectedAttorney(tempSelectedAttorney);
  };

  const handelToggleAttorney = (attorneyItem) => {
    const index = selectedAttorney.findIndex(item => item.stock_code === attorneyItem.stock_code);
    const tempSelectedAttorney = [... selectedAttorney];
    if(index === -1) {
      tempSelectedAttorney.push({
        user_id: localUser.id,
        stock_code: attorneyItem.stock_code,
        auto_pic: attorneyItem.auto_count.toString(),
        manual_pic: attorneyItem.manual_count.toString(),
        void_pic: "0",
        acq_all: attorneyItem.acq_all === true ? "1" : "0",
        notes: addDollarSigns(attorneyItem.notes),
        year: currentYear - 1911
      });
      setSelectedAttorney(tempSelectedAttorney);
    } else {
      tempSelectedAttorney.splice(index, 1);
      setSelectedAttorney(tempSelectedAttorney);
    };
  }

  const handelToggleAllAttorney = () => {
    if(selectAllAttorney) {
      setSelectedAttorney([]);
      setSelectAllAttorney(false);
    } else {
      const tempSelectedAttorney = [... selectedAttorney];
      const tempList = searchSortedList.length > 0 ? searchSortedList : sortedList;
      tempList.forEach(attorney => {
        const index = selectedAttorney.findIndex(item => item.stock_code === attorney.stock_code);
        if(index == -1 && (attorney.auto_count !== 0 || attorney.manual_count !== 0)) {
          tempSelectedAttorney.push({
            user_id: localUser.id,
            stock_code: attorney.stock_code,
            auto_pic: attorney.auto_count.toString(),
            manual_pic: attorney.manual_count.toString(),
            void_pic: "0",
            acq_all: attorney.acq_all === true ? "1" : "0",
            notes: addDollarSigns(attorney.notes),
            year: currentYear - 1911
          })
        }
      });
      setSelectedAttorney(tempSelectedAttorney);
      setSelectAllAttorney(true);
    }
  }

  const handelUploadAttorney = async () => {
    const hasEmpty = selectedAttorney.some(attorney => !attorney.manual_pic)
    if(hasEmpty) {
      window.alert("不收購不可為空值")
    } else {
      setLoading(true);
      await uploadAttorney(selectedAttorney, localUser.accessToken).then((result) => {
        if(result) {
          setSelectedAttorney([]);
          setLoading(false);
          getStockList();
        }
      })
    }
  }

  function addDollarSigns(inputString) {
    const stringWithDollarSigns = `$${inputString}$`;
    return stringWithDollarSigns;
  }

  function onAttorneyItemNotesChange(e, attorneyItem) {
    attorneyItem.notes = e.target.value;
    const index = selectedAttorney.findIndex(item => item.stock_code === attorneyItem.stock_code);
    if (index !== -1) {
      const tempSelectedAttorney = [... selectedAttorney];
      tempSelectedAttorney[index].notes = addDollarSigns(e.target.value);
      setSelectedAttorney(tempSelectedAttorney);
    }
  };

  function formateTime(time) {
    const originalDate = new Date(time);

    // 提取年、月、日
    const year = originalDate.getFullYear().toString().slice(2); // 提取后两位
    const month = (originalDate.getMonth() + 1).toString().padStart(2, '0'); // 月份从0开始
    const day = originalDate.getDate().toString().padStart(2, '0');

    // 格式化为 "YY/MM/DD" 的字符串
    const formattedDateString = `${year}/${month}/${day}`;
    return(formattedDateString);
  };

  const renderToggleAll = () => {
    const hasNonZeroCount = sortedList.some(item => item.auto_count !== 0 || item.manual_count !== 0);
    if (hasNonZeroCount) {
      return (
        <th>{selectAllAttorney ? <FaRegCheckSquare onClick={() => handelToggleAllAttorney()}/> : <FaRegSquare onClick={() => handelToggleAllAttorney()}/>}</th>
      )
    } else {
      return (
        <th></th>
      )
    }
  }

  function handleSearchTextChange(e) {
    setSearchText(e.target.value);
    setSearch(e.target.value);
  }

  function handleSearch() {
    setSearch(searchText);
  }

  function handleKeyPress(e) {
    if(e.which === 13) 
    handleSearch();
  }

  const handleStockNameNewLine = (name) => {
    const items = [];

    // 将源字符串按照每行 10 个字符的方式拆分成数组
    for (let i = 0; i < name.length; i += 19) {
      const line = name.substring(i, i + 19);
      items.push(line);
    }

    return items.join('\n');
  }

  const renderForm = () => {
    let serialNumber = 0;
    const renderAttorneyList = searchSortedList.length != 0 ? searchSortedList : search != "" ? [] : sortedList;
    return(
      renderAttorneyList.map((attorneyItem, index) => {
        const include = selectedAttorney.some((attorney) => attorney.stock_code === attorneyItem.stock_code);
        const url = "https://doc.twse.com.tw/server-java/t57sb01?step=1&colorchg=1&co_id=" + attorneyItem.stock_code + "%20%20%20%20%20%20&year=" + attorneyItem.year + "&mtype=F&";
        if(attorneyItem.gift_item != "" && attorneyItem.total_upload != 0) {
          return(
            <tr key={index} className={`hover height-1 ${index % 2 != 0 ? "odd-row" : ""}`}>
              <td>{serialNumber += 1}</td>
              <td>
              {include ?
              <FaRegCheckSquare onClick={() => handelToggleAttorney(attorneyItem)}/> : 
              <FaRegSquare onClick={() => handelToggleAttorney(attorneyItem)}/>}
              </td>
              <td><a href={url} target="_blank">{attorneyItem.stock_code}</a></td>
              <td className={`${attorneyItem.note.includes("正身") || attorneyItem.note.includes("正文") ? "text_red bold" : ""}`}>{attorneyItem.stock_name}</td>
              <td className="width-50">
                {attorneyItem.gift_item != null && attorneyItem.gift_item.length > 19 ? handleStockNameNewLine(attorneyItem.gift_item).split('\n').map((line, index) => (
                  <React.Fragment key={index}>
                    {line}
                    <br />
                  </React.Fragment>
                )) : attorneyItem.gift_item}
              </td>
              <td>{attorneyItem.acq_price}</td>
              <td className='num_colm'>{attorneyItem.auto_count}</td>
              <td className='height-1'><input className='manual_count_input height-100' type='number' value={attorneyItem.manual_count} min={0} max={attorneyItem.total_upload} onChange={(e) => onManualCountChange(e, attorneyItem)}/></td>
              <td className='num_colm'>{attorneyItem.total_upload}</td>
              <td>{formateTime(attorneyItem.deadline.split(" ")[0])}</td>
              <td>{attorneyItem.note}</td>
              <td className='height-1'><input className='note_input height-100' type='text' maxLength={15} onChange={(e) => onAttorneyItemNotesChange(e, attorneyItem)}/></td>
            </tr>
          )
        }
      })
    )
   
  }

  const onManualCountChange = (e, attorneyItem) => {
    const tempList = [... sortedList];
    const index = selectedAttorney.findIndex(item => item.stock_code === attorneyItem.stock_code);
    if(e.target.value < 0) {
      window.alert("不可設低於0");
      e.target.value = 0;
    } else if(e.target.value > attorneyItem.total_upload) {
      window.alert("不可設高於總數");
      e.target.value = attorneyItem.total_upload;
    } else {
      attorneyItem.manual_count = e.target.value;
      attorneyItem.auto_count = attorneyItem.total_upload - e.target.value;
    }
    setSortedList(tempList);
    if (index !== -1) {
      const tempSelectedAttorney = [... selectedAttorney];
      tempSelectedAttorney[index].manual_pic = e.target.value;
      tempSelectedAttorney[index].auto_pic = attorneyItem.total_upload - e.target.value;
      setSelectedAttorney(tempSelectedAttorney);
    }
  }

  const renderSelectTotal = () => {
    let totalAttorney = 0;
    selectedAttorney.forEach(attorney => {
      totalAttorney += Number(attorney.auto_pic) + Number(attorney.manual_pic)
    });
    return totalAttorney;
  }

  return (
    <>
      <div className='attorney-root'>
        <Nav/>
        <div className="attorney-background">
          <div className="attorney-paper-title">交單</div>
          <div className="attorney-button-bar">
            <MDBInputGroup
              material
              containerClassName="search-block col-12"
              hint="Search.."
              onChange={handleSearchTextChange}
              onKeyPress={handleKeyPress}
              value={searchText}
              append={
                <button
                  rounded
                  size="sm"
                  type="submit"
                  className="mr-auto search-button"
                  onClick={handleSearch}
                >
                  Search
                </button>
              }
            />
            <Select className='attorney-year-select' value={yearSelected} options={yearOptions} onChange={handleYearChange}/>
          </div>
          <div className="attorney-data-form">
            <table>
              <thead>
                <tr>
                  <th>編號</th>
                  {renderToggleAll()}
                  {longSortItemList.map((content, index) => {
                    return (
                      <th key={index} onClick={() => handleSort(content.name)}>
                        {content.name == "acq_all" ? selectAcqAll ? <FaRegCheckSquare className="select-icon" onClick={handelAcqAll}/> : <FaRegSquare className="acq-all-icon" onClick={handelAcqAll}/> : ""}
                        {content.title}
                        {sortSelect === content.name ?
                          renderSortIcon(content.sortKey)
                          : null}
                      </th>
                    )
                  })}
                </tr>
              </thead>
              <tbody>
                {sortedList != null ?  renderForm() : null}
              </tbody>
            </table>
            {selectedAttorney.length != 0 ? <div className="selected-count">{"總共勾選: " + renderSelectTotal() + "張"}</div> : null}
            {selectedAttorney.length != 0 ?
              <div>
                <button className='submit-button' onClick={handelUploadAttorney}>交單</button>
              </div> : ""}
          </div>
        </div>
      </div>
      <Loading loading={loading}/>
      <UploadScreenShot
        show={showUploadScreenShot}
        onHide={handleOnHideUploadScreenShot}
        stock_id= {selectedStockCode}
        uploadFunction={handelUploadScreenShot}/>
      <Confirm
       show={showUploadResultConfirm}
       title={"確認"}
       onHide={handleOnHideUploadResultConfirm}
       message={"截圖上傳結果"}
       uploadResult={uploadResult}
       reLoadWindows= {reLoadWindows}
      />
    </>
  )
};

export default connect(
  null,
  {  }
)(AttorneyPage);