import React, { Component } from 'react';
import { useState, useEffect } from "react"; 
import { useDispatch, useSelector } from "react-redux/";
// import { logout } from "../../redux/actions/adminActions";
import "../styles/Nav.css";
import { useNavigate } from "react-router-dom"; 
import Button from 'react-bootstrap/Button';

export default function Nav(props) {
  const [menuOpen, setMenuOpen] = useState(true); 
  const user = useSelector((state) => state.adminReducer.user);
  const navigate  = useNavigate(); 
  const localUser = JSON.parse(sessionStorage.getItem("user"));

  const logOut = () => {
    sessionStorage.clear();
    navigate("/")
  }

  return (
    <div  className="nav-btn-group">
      {localUser ? <span className="nav-btn" onClick={() => {navigate("/billboard")}}>公告欄</span> : null}
      <span className="nav-btn" onClick={() => {navigate("/")}}>紀念品</span>
      {localUser && localUser.account_level === 1 ? <span className="nav-btn" onClick={() => {navigate("/attorneysummarypage")}}>交單</span> : null}
      {localUser && localUser.account_level === 2 ? <span className="nav-btn" onClick={() => {navigate("/attorneyupload")}}>交單</span> : null}
      {localUser && localUser.account_level === 2 ? <span className="nav-btn" onClick={() => {navigate("/attorneyconfirm")}}>查詢</span> : null}
      {localUser ? <span className="nav-btn" onClick={() => {navigate("/screenshotdownload")}}>電投表</span> : null}
      {localUser && localUser.account_level === 1 ? <span className="nav-btn" onClick={() => {navigate("/reportpage")}}>總表</span> : null}
      {localUser ? <span className="nav-btn" onClick={() => {navigate("/shipment")}}>出貨</span> : null}
      <span className="nav-btn" onClick={() => {navigate("/souvenirinventory")}}>建議清冊+紀念品總表</span>
      <span className="nav-btn" onClick={() => {navigate("/precautions")}}>注意事項</span>
      <span className="nav-btn" onClick={() => {navigate("/aboutme")}}>關於我</span>
      {localUser ? <span className="nav-btn" onClick={() => {navigate("/idmanagement")}}>會員</span> : null}
      {localUser ? null : <span className="nav-btn" onClick={() => {navigate("/register")}}>註冊</span>}
      {localUser ? null : <span className="nav-btn" onClick={() => {navigate("/login")}}>登入</span>}
      {localUser ? <div className="nav-btn" onClick={logOut}>登出</div> : null}
    </div>
  )
}
  