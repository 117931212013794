export const setUser = (payload) => {
    return {
        type: "SET_USER",
        payload: payload,
    };
}

export const setAccount = (payload) => {
    return {
        type: "SET_ACCOUNT",
        payload: payload,
    };
}

export const setIdList = (payload) => {
    return {
        type: "SET_PHYIDLIST",
        payload: payload,
    };
}

export const setStockList = (payload) => {
    return {
        type: "SET_STOCK",
        payload: payload,
    };
}

