import React from 'react';
import { connect } from "react-redux";
import Nav from "../../components/Nav"
import "./style.css";
import { useState, useEffect } from "react"; 
import { getBillboardList, addBillboard, editBillboard, removeBillboard } from "../../actions/billvoardAction";
import { BiSolidUpArrow, BiSolidDownArrow } from "react-icons/bi";
import { AiOutlineDelete, AiFillEdit } from "react-icons/ai";
import Confirm from "../../components/modals/confirm";

function BillboardPage(props) {
  const billBoardSortItemList = [
    { name: "time", sortKey: "down", title: "新增時間" },
    { name: "title", sortKey: "down", title: "標題" },
    { name: "content", sortKey: "down", title: "內容" },
  ];

  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [billboardList, setBillboardList] = useState([]);
  const [ phyIdSortSelect, setPhyIdSortSelect ] = useState("time");
  const [ sortList, setSortList ] = useState(billBoardSortItemList);
  const [ showEditConfirm, setShowEditConfirm ] = useState(false);
  const [ showRemoveConfirm, setShowRemoveConfirm ] = useState(false);
  const [ selectedBillboard, setSelectedBillboard ] = useState(null);

  const localUser = JSON.parse(sessionStorage.getItem("user"));

  useEffect(() => {
    const getBillboardData = async () => {
      const result = await getBillboardList(localUser.accessToken);
      if(result != null ) {
        setBillboardList(result)
      }
    };

    getBillboardData();
  }, []);

  const onTitleChange = (e) => {
    setTitle(e.target.value);
  }

  const onDescriptionChange = (e) => {
    setDescription(e.target.value);
  }

  async function  saveBillboard() {
    const result = await addBillboard(title, description, localUser.accessToken);
    if(result != null ) {
      reLoadWindows()
    }
  }

  const handleSort = (sortKey) => {
    setPhyIdSortSelect(sortKey);
    handleSortChange(sortKey)
  };

  const handleSortChange = (sortKey) => {
    let nextSort = "";
    let tempSortedList = [...billboardList];
    let tempSortItemList = [...sortList];
    tempSortItemList.map((content) => {
      if (content.name === sortKey) {
        nextSort = content.sortKey === "down" ? "up" : "down";
        sortByKey(tempSortedList, sortKey, content.sortKey);
        content.sortKey = nextSort;
        return content.sortKey
      }
      return null
    })
    setSortList(tempSortItemList);
    setBillboardList(tempSortedList);
  };

  const sortByKey = (array, sortKey, sortRule) => {
    if (sortRule === "down") {
      return array.sort((a, b) => {
        return ((a[sortKey] < b[sortKey]) ? -1 : ((a[sortKey] > b[sortKey]) ? 1 : 0));
      });
    }
    else {
      return array.sort((a, b) => {
        return ((a[sortKey] > b[sortKey]) ? -1 : ((a[sortKey] < b[sortKey]) ? 1 : 0));
      });
    }
  }

  const renderSortIcon = (sortKey) => {
    return sortKey === "down" ? <BiSolidDownArrow className="sort-icon" /> : <BiSolidUpArrow className="sort-icon" />
  };

  function formateTime(time) {
    const originalDate = new Date(time);

    // 提取年、月、日
    const year = originalDate.getFullYear().toString().slice(2); // 提取后两位
    const month = (originalDate.getMonth() + 1).toString().padStart(2, '0'); // 月份从0开始
    const day = originalDate.getDate().toString().padStart(2, '0');

    // 格式化为 "YY/MM/DD" 的字符串
    const formattedDateString = `${year}/${month}/${day}`;
    if(time == "0000/00/00") {
      return("00/00/00");
    } else {
      return(formattedDateString);
    }
  };

  const handleOnShowEditConfirm = (billboard) => {
    setSelectedBillboard(billboard);
    setShowEditConfirm(true);
  };

  const handleOnShowRemoveConfirm = (billboard) => {
    setSelectedBillboard(billboard);
    setShowRemoveConfirm(true);
  };

  const handleOnHideEditConfirm = () => {
    setShowEditConfirm(false);
  };

  const handleOnHideRemoveConfirm = () => {
    setShowRemoveConfirm(false);
  };

  const reLoadWindows = () => {
    window.location.reload();
  }

  const hadelEditbillboard = async (title, content) => {
    const result = await editBillboard(selectedBillboard.id, title, content, localUser.accessToken);
    if(result) {
      reLoadWindows()
      handleOnHideEditConfirm();
    }
  };

  const hadelRemovebillboard = async () => {
    const result = await removeBillboard(selectedBillboard.id, localUser.accessToken);
    if(result) {
      reLoadWindows()
      handleOnHideEditConfirm();
    }
  };

  const renderContent = (text) => {
    const urlRegex = /(https?:\/\/[^\s]+)/gi;
    
    return text.split(urlRegex).map((part, index) => {
      if (urlRegex.test(part)) {
        // 如果是网址，则返回超链接
        return <a key={index} href={part} className='url' target="_blank" rel="noopener noreferrer">{part}</a>;
      } else {
        // 否则返回普通文本
        return part;
      }
    });
  };

  return(
    <div className='billboard-root'>
      <Nav/>
      <div className="billboard-background">
        <div className="billboard-title">公告欄</div>
        <div className="billboard-display">
          {localUser.account_level == 1 ?
          <div className='billboard-edit'>
            <div className='title-input-bar'>
              <div className='title-text'>標題:</div>
              <input className='input-text' type='text' onChange={onTitleChange} value={title}/>
            </div>
            <div className='title-input-bar margin-top'>
              <div className='title-text'>內容:</div>
              <textarea className='input-text-area' onChange={onDescriptionChange} value={description}/>
            </div>
            <button className='save-buttom' onClick={() => saveBillboard()}>儲存</button>
          </div> : ""}
          <div className='billboard-form'>
            <table className='billboard-table'>
              <thead>
                {billBoardSortItemList.map((content, index) => {
                  return(
                    <th 
                      onClick={() => { handleSort(content.name) }}>
                        {content.title}
                        {phyIdSortSelect === content.name ? renderSortIcon(content.sortKey) : null}
                    </th>
                  )
                })}
                {localUser.account_level == 1 ? <th>功能</th> : ""}
              </thead>
              <tbody>
                {billboardList.map((billboard) => {
                  return(
                    <tr>
                      <td className='padding-10'>{formateTime(billboard.time.split(" ")[0].split("-").join("/"))}</td>
                      <td className='billboard-table-title'>{billboard.title}</td>
                      <td className='billboard-content'>{renderContent(billboard.content)}</td>
                      {localUser.account_level == 1 ? 
                        <td >
                          <AiOutlineDelete  className="delete-icon" onClick={() => handleOnShowRemoveConfirm(billboard)}/> 
                          <AiFillEdit className="select-icon" onClick={() => handleOnShowEditConfirm(billboard)}/>
                        </td>
                         : ""}
                    </tr>
                  )
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <Confirm
        show={showEditConfirm}
        onHide={handleOnHideEditConfirm}
        message={"確認要修改此公告資訊嗎?"}
        reLoadWindows={reLoadWindows}
        selectedBillboard={selectedBillboard}
        billboardRemove={false}
        function= {hadelEditbillboard}/>
      <Confirm
        show={showRemoveConfirm}
        onHide={handleOnHideRemoveConfirm}
        message={"確認要刪除此公告資訊嗎?"}
        reLoadWindows={reLoadWindows}
        selectedBillboard={selectedBillboard}
        billboardRemove={true}
        function= {hadelRemovebillboard}/>
    </div>
  )
}
  
export default connect(
  null,
  {  }
)(BillboardPage);