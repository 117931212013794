import React from "react";
import { connect } from "react-redux";
// import { toggleModifier, validateOrder } from "../../redux/actions";
import { withRouter } from "react-router-dom";
import { useState, useEffect } from "react"; 
import { Modal, Button, Form } from "react-bootstrap";
import { addUser } from "../../actions/adminAction";
import { useNavigate } from "react-router-dom"; 
import "../../styles/uploadPhyPic.css";
import 'bootstrap/dist/css/bootstrap.min.css';


export default function UploadPhyPic(props) {
  const [ uploadFileUrl, setUploadFileUrl ] = useState(null);
  const [ uploadFile, setUploadFile ] = useState(null);
  const localUser = JSON.parse(sessionStorage.getItem("user"));
  const navigate  = useNavigate(); 

  useEffect(() => {
    window.onpopstate = () => {
      if (document.getElementById("modalConfirm")) {
        props.dismiss();
      }
    };
  }, []);

  useEffect(() => {
    if(props.phyId && props.phyId.id_pic_path != null) {
      setUploadFileUrl("https://5205.tw"+props.phyId.id_pic_path);
    } else {
      setUploadFileUrl(null)
    }
  }, [props.phyId]);

  const handleOnHide = () => {
    props.onHide();
  };

  const handleOnUpload = async () => {
    props.function(uploadFile);
    setUploadFileUrl(null);
    setUploadFile(null);
  };

  const handelUploadFile = e => {
    setUploadFile(e.target.files[0]);
    setUploadFileUrl(URL.createObjectURL(e.target.files[0]));
  }

  return (
    <div>
      {props.show && (
      <div className="custom-modal-bg"></div>
    )}
    <Modal show={props.show} onHide={(props.onHide, handleOnHide)} animation={false} size="lg" 
    className="custom-modal" 
    aria-labelledby="contained-modal-title-vcenter"
    centered>
      <Modal.Body>
      <div className="upload-phy-pic-background">
        <div className="upload-phy-pic-box">
          { props.phyId ? <div className="notice-text">
            <div>1.健保卡優先</div>
            <div>2.圖片清楚無反光</div>
            <div>3.未成年: 健保卡</div>
          </div> : null}
          <div className="upload-phy-pic-form">
          { props.phyId ? 
          <>
            <div>
              <span className="upload-phy-pic-label">所屬會員: </span>
              <span className="upload-phy-pic-text">{props.user.nickname}</span>
            </div>
            <div>
              <span className="upload-phy-pic-label">姓名: </span>
              <span className="upload-phy-pic-text">{props.phyId.name}</span>
            </div>
            <div>
              <span className="upload-phy-pic-label">身分證字號: </span>
              <span className="upload-phy-pic-text">{props.phyId.phy_id_string}</span>
            </div>
          </> : null}
          { props.stock ? <>
            <div>
              <span className="upload-phy-pic-label">股號: </span>
              <span className="upload-phy-pic-text">{props.stock.stock_code}</span>
            </div>
            <div>
              <span className="upload-phy-pic-label">股名: </span>
              <span className="upload-phy-pic-text">{props.stock.stock_name}</span>
            </div>
            <div>
              <span className="upload-phy-pic-label">紀念品: </span>
              <span className="upload-phy-pic-text">{props.stock.gift_item}</span>
            </div>
          </> : null}
            
            <div>
              <span className="upload-phy-pic-label">上傳檔案: </span>
              <input type="file" onChange={handelUploadFile} />
            </div>
          </div>
          <img className="upload-phy-pic-preview" src={uploadFileUrl}/>
        </div>
      </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleOnHide}>
          取消
        </Button>
        <Button variant="primary" onClick={handleOnUpload}>
          上傳
        </Button>
      </Modal.Footer>
    </Modal>
    </div>
  );
}