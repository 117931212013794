import React from "react";
import { Modal, Button, Form } from "react-bootstrap";
import "../../styles/newId.css";
import { useState, useEffect } from "react"; 
import 'bootstrap/dist/css/bootstrap.min.css';

export default function Confirm(props) {
  const [notes, setNotes] = useState("");
  const [ voidCount, setVoidCount ] = useState(1);
  const [ autoCount, setAutoCount ] = useState(0);
  const [ manualCount, setManualCount ] = useState(0);
  const [ editNote, setEditNote ] = useState("");
  const [ editBillboardTitle, setEditBillboardTitle ] = useState("");
  const [ editBillboardContent, setEditBillboardContent ] = useState("");

  useEffect(() => {
    if(props.editAttorney) {
      setAutoCount(props.editAttorney.auto_pic);
      setManualCount(props.editAttorney.manual_pic);
      setEditNote(props.editAttorney.notes ? props.editAttorney.notes : "");
    } else if(props.selectedBillboard) {
      setEditBillboardTitle(props.selectedBillboard.title);
      setEditBillboardContent(props.selectedBillboard.content);
    }
  }, [props.editAttorney, props.selectedBillboard]);

  useEffect(() => {
    if(props.uploadResult) {
      const tableRows = Object.entries(props.uploadResult).map(([key, values]) => (
        <tr key={key}>
          <td>{key}</td>
          {values.map((value, index) => (
            <td key={index}>{value}</td>
          ))}
        </tr>
      ));
    }
    
  }, [props.uploadResult]);

  const handleOnRemove = async () => {
    if(props.removeAttorney) {
      if(props.isSeparateRemove) {
        if(voidCount <= 0) {
          window.alert("退單數量不可小於等於0");
        } else if(props.toggleAttorney.confirm_count - props.toggleAttorney.void_count - voidCount < 0) {
          window.alert("退單數量不可大於確認份數");
        } else {
          const result = props.function(notes, props.isSeparateRemove, voidCount);
          setVoidCount(0);
          setNotes("")
        }
      } else {
        const result = props.function(notes, props.isSeparateRemove, voidCount);
        setVoidCount(0);
        setNotes("")
      }
    } else if(props.removeAttorneyUpload || props.multipleVoid) {
      const result = props.function(notes);
      setNotes("")
    } else if(props.editAttorney) {
      if(Number(autoCount) + Number(manualCount) == props.editAttorney.confirm_count) {
        if(autoCount != "" && manualCount != "") {
          const result = props.function(props.editAttorney, autoCount, manualCount);
          setEditNote("");
        } else {
          window.alert("收購與不收購不可為空值")
        }
        
      } else {
        window.alert("收購與不收購總和與確認份數不相符!")
      }
      
    } else if(props.selectedBillboard && !props.billboardRemove) {
      const result = props.function(editBillboardTitle, editBillboardContent);
    } else {
      const result = props.function();
    }
  };

  const handleOnHide = () => {
    setEditNote("");
    props.onHide();
  };

  function handleNotesChange(e) {
    setNotes(e.target.value)
  };

  function handleVoidNumberChange(e) {
      setVoidCount(e.target.value);
  };

  function padId(id) {
    const strId = String(id);
    const paddingLength = 8 - strId.length;
    
    if (paddingLength <= 0) {
      return strId;
    }
  
    const paddingZeros = '0'.repeat(paddingLength);
    return paddingZeros + strId;
  };

  function handleEditAutoPicChange(e) {
    if(Number(e.target.value) + Number(manualCount) <= Number(props.editAttorney.confirm_count))
    setAutoCount(e.target.value);
  };

  function handleEditManualPicChange(e) {
    if(Number(e.target.value) + Number(autoCount) <= Number(props.editAttorney.confirm_count))
    setManualCount(e.target.value);
  };


  function handleEditAttorneyNote(e) {
    setEditNote(e.target.value);
  };

  function handleEditBillboardTitle(e) {
    setEditBillboardTitle(e.target.value);
  };

  function handleEditBillboardContent(e) {
    setEditBillboardContent(e.target.value);
  };
  
  const TableComponent = () => {
    const tableHeaders = Object.keys(props.uploadResult);
    const tableRows = Object.values(props.uploadResult);
    const numRows = tableRows[0].length;
    return (
      <table className="margin-top" border="1">
        <thead>
          <tr>
            {tableHeaders.map((header, index) => (
              <th key={index}>{header}</th>
            ))}
          </tr>
        </thead>
        <tbody>
        {[...Array(numRows)].map((_, rowIndex) => (
            <tr key={rowIndex}>
              {tableRows.map((row, colIndex) => (
                <td key={colIndex}>{row[rowIndex]}</td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    );
  };

  return (
    <Modal show={props.show} onHide={(props.onHide, handleOnHide)} animation={false} size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered>
      <Modal.Header>
        <Modal.Title className="new-id-title">{props.title}</Modal.Title>
      </Modal.Header>
      <Modal.Body> 
        {props.phyIdInfo ? <div>{"帳號: " + props.phyIdInfo.name + " 身分證字號: " + props.phyIdInfo.phy_id_string} </div> : null}
        {props.attorneyInfo ? <div>{"單號: " + props.attorneyInfo} {props.confirmRemoveAttorneyInfo ? "  股名: " + props.confirmRemoveAttorneyInfo.stock_name + "  交單時間: " + props.confirmRemoveAttorneyInfo.submit_date.split(" ")[0].split("-").join("/") :""}</div> : null}
        {props.toggleAttorney ? 
          props.isSeparateRemove ? 
            <div>
              {"單號: " + padId(props.toggleAttorney.attorneyId) + "  股號: " + props.toggleAttorney.stockCode + "  退單數量: "}
              <input value={voidCount} type="number" min={0} max={props.toggleAttorney.confirm_count} onChange={handleVoidNumberChange}/>
            </div>
              :
          props.toggleAttorney.map((attorney) => {
            return(
              <div>
                {"單號: " + padId(attorney.attorneyId) + "  股號: " + attorney.stockCode + "  退單數量: " + (attorney.confirm_count - attorney.void_count)}
              </div>
            )})
          : null}
        {props.attorneys ?
        <div>
          {"單號: "}
          {props.attorneys.map(attorney => {
            return(
              <span>{padId(attorney.id) + ", "}</span>
            )
          })}
        </div> : null}
        {props.editAttorney ? 
        <div>
          <div>
            {"單號: " + padId(props.editAttorney.attorneys_id)}
          </div>
          <div>
            {" 股號: " + props.editAttorney.stock_code}
          </div>
          <div>
            {"確認份數: "}
            <span>{props.editAttorney.confirm_count}</span>
          </div>
          <div>
            {"收購: "}
            <input type="number" value={autoCount} min={0} max={Number(props.editAttorney.confirm_count) - Number(manualCount)} onChange={handleEditAutoPicChange}/>
          </div>
          <div>
            {"不收購: "}
            <input type="number" value={manualCount} min={0} max={Number(props.editAttorney.confirm_count) - Number(autoCount)} onChange={handleEditManualPicChange}/>
          </div>
        </div> : null}
        {props.selectedBillboard ? 
        <div>
          <div>
            <span>{"標題: "}</span>
            <span>
            {props.billboardRemove ? <span>{editBillboardTitle}</span> :
              <input type="text" value={editBillboardTitle} onChange={handleEditBillboardTitle}/> }
            </span>
          </div>
          <div className="billboard-edit-content">
            <span>{"內容: "}</span>
            <span>
              {props.billboardRemove ? editBillboardContent :
              <textarea className="billboard-edit-textarea" value={editBillboardContent} onChange={handleEditBillboardContent}/>}
            </span>
          </div>
        </div>: null}
        {props.voidInfo ? 
          <table>
            <thead>
              <th>股號</th>
              <th>單號</th>
              <th>退單數量</th>
              <th>備註</th>
            </thead>
            <tbody>
              {props.voidInfo.map(voidInfo => {
                return(
                  <tr>
                    <td>{voidInfo.stock_code}</td>
                    <td>{voidInfo.id}</td>
                    <td>{voidInfo.void_amount}</td>
                    <td>{voidInfo.note}</td>
                  </tr>
                )
              })}
            </tbody>
          </table>
         : null}
         {props.multipleVoid ? 
          <table>
            <thead>
              <th>股號</th>
              <th>交單數量</th>
            </thead>
            <tbody>
              {
                props.multipleVoid.map((attorney, index) => {
                  return(
                    <tr key={index}>
                      <td>{attorney.stock_code}</td>
                      <td>{Number(attorney.manual_pic) + Number(attorney.auto_pic)}</td>
                    </tr>
                  )
                })
              }
            </tbody>
          </table> : null}
          {props.shipmentList && (
            <table>
              <thead>
                <tr>
                  <th>編號</th>
                  <th>股號</th>
                  <th>股名</th>
                  <th>紀念品</th>
                  <th>出貨</th>
                  <th>收購</th>
                  <th>退單</th>
                  <th>交單備註</th>
                  <th>出貨備註</th>
                </tr>
              </thead>
              <tbody>
              {props.shipmentList.map((shipment, index) => {
                return(
                  <tr>
                    <td>{index + 1}</td>
                    <td>{shipment.stock_code}</td>
                    <td>{shipment.stock_name}</td>
                    <td className="width-20">{shipment.gift_item}</td>
                    <td className='width-shipment-5'>{shipment.unacq_count}</td>
                    <td className='width-shipment-5'>{shipment.acq_count}</td>
                    <td className='width-shipment-5'>{shipment.void_count}</td>
                    <td className="width-20">{shipment.attorney_note}</td>
                    <td className="width-20">{shipment.shipped_content}</td>
                  </tr>
                )
              })}
              </tbody>
            </table>
          )}
        {props.message ? props.message : null}
        {props.uploadResult ? TableComponent() : null}
        {props.removeAttorney || props.removeAttorneyUpload || props.multipleVoid ? 
        <div>
          <span>備註: </span>
          <input type="text" value={notes} onChange={handleNotesChange}/> 
        </div> : null}
      </Modal.Body>
      {props.ScreenShotDownload || props.uploadResult || props.voidInfo ? null :
      <Modal.Footer>
        <Button variant="secondary" onClick={handleOnHide}>
          取消
        </Button>
        <Button variant="primary" onClick={handleOnRemove}>
          確認
        </Button>
      </Modal.Footer>}
    </Modal>
  );
}