const initialState = {
    stock: null,
  };
  
  const stock = (state = initialState, action) => {
      switch (action.type) {
        case "SET_STOCK":
          return {...state,
            stock: action.payload,
          };
        default:
          return (state);
      }
  };
  
  export default stock;