import React from "react";
import { connect } from "react-redux";
// import { toggleModifier, validateOrder } from "../../redux/actions";
import { withRouter } from "react-router-dom";
import { useState, useEffect } from "react"; 
import { Modal, Button, Form } from "react-bootstrap";
import "../../styles/newId.css";
import 'bootstrap/dist/css/bootstrap.min.css';


export default function NewId(props) {
  const [state, setState] = useState({ name: "", phy_id_string: "", cert_type: 0, seq: 0 });
  const localUser = JSON.parse(sessionStorage.getItem("user"));

  useEffect(() => {
    window.onpopstate = () => {
      if (document.getElementById("modalConfirm")) {
        props.dismiss();
      }
    };
  }, []);

  useEffect(() => {
    if(props.data) {
      setState({ 
        name: props.data === null ? "" : props.data.name, 
        phy_id_string: props.data === null ? "" : props.data.phy_id_string,
        seq: props.data === null ? 0 : props.data.seq ,
        cert_type: props.data === null ? 0 : props.data.cert_type,
      })
    }
  }, [props.data]);

  const handleOnHide = () => {
    props.onHide();
  };

  const handleOnSave = async () => {
    if(props.editIdList != null) {
      const index = props.editIdList.findIndex(item => item.seq === parseInt(state.seq));
      props.function(state);
    } else {
      if(state.name !== "" && state.phy_id_string !== "") {
        props.function(state);
      } else {
        window.alert("請輸入姓名及身分證字號")
      }
    }
  };

  const handleChange = e => {
    setState({ ...state, [e.target.id]: e.target.value });
  };

  return (
    <div>
      {props.show && (
      <div className="custom-modal-bg"></div> /* 自定义背景元素 */
    )}
    <Modal show={props.show} onHide={(props.onHide, handleOnHide)} animation={false} size="lg" 
    className="custom-modal" 
    aria-labelledby="contained-modal-title-vcenter"
    centered>
      <Modal.Header>
        <Modal.Title className="new-id-title">{props.title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="new_form">
          <div >身分證字號</div>
          <input  id="phy_id_string" onChange={handleChange} value={state.phy_id_string}/>
        </div>
        <div className="new_form">
          <div >姓名</div>
          <input className="new-form-input" type="text" id="name" onChange={handleChange} value={state.name}/>
        </div>
        {props.data != null && props.localUser.account_level === 1? 
        <div className="new_form">
          <div >證件</div>
          <select className="new-form-input" id="cert_type" value={state.cert_type} onChange={handleChange}>
            <option value={0}>沒證件</option>
            <option value={1}>戶口名簿</option>
            <option value={2}>戶籍</option>
            <option value={3}>身分證</option>
            <option value={4}>健保卡</option>
            <option value={5}>駕照</option>
          </select>
        </div> : null}
        {props.data != null && props.localUser.account_level === 1? 
        <div className="new_form">
          <div >順序</div>
          <input className="new-form-input" type="number" id="seq" onChange={handleChange} value={state.seq}/>
        </div> : null}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleOnHide}>
          取消
        </Button>
        <Button variant="primary" onClick={handleOnSave}>
          {props.submitText}
        </Button>
      </Modal.Footer>
    </Modal>
    </div>
    
  );
}