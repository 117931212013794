import { S_1_0 } from "../configs/config";

export const getStockPicCount = async (user_id, stock_code, token) => {
  try {
    const response = await fetch(`${S_1_0}/attorney/uploadcount/`, {
      method: "POST",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        "Authorization": "Bearer " + token,
      },
      body: JSON.stringify({
        user_id: user_id,
        stock_code: stock_code
    }),
    });
    if (response.ok) {
      const uploadFileCount = await response.json();
      return uploadFileCount;
    } else {
      return null;
    }
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
};

export const uploadScreenShot = async (user_login, stock_code, fileList, year, token) => {
  let formData = new FormData();
  let fileInfoList = [];
  for (let index = 0; index < fileList.length; index++) {
    const fileType = fileList[index].type.split("/")[1];
    let mettingType = ""
    if(stock_code.includes("t") || stock_code.includes("T")) {
      mettingType = "temp"
    } else {
      mettingType = "regular"
    }
    fileInfoList.push(
      {
        type: "companyshot",
        stock_code: stock_code,
        username: user_login,
        FileName: user_login + "_" + stock_code + "_" + mettingType +"_" + index + "_."  + fileType,
        year: year.label
    })
  }
  for (let index = 0; index < fileList.length; index++) {
    formData.append(
      "fileToUpload[]",
      fileList[index]
    );
  };
  formData.append(
    "fileInfo",
    JSON.stringify(fileInfoList)
  );
  try {
    const response = await fetch(`${S_1_0}/file/upload/`, {
      method: "POST",
      mode: "cors",
      headers: {
        "Authorization": "Bearer " + token,
      },
      body: formData
    });
    if (response.ok) {
      const attorneyList = await response.json();
      if(attorneyList.output) {
        return attorneyList.output;
      }
    } else {
      return null;
    }
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
}

export const uploadAttorney = async (selectedAttorney, token) => {
  try {
    const response = await fetch(`${S_1_0}/attorney/add/`, {
      method: "POST",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        "Authorization": "Bearer " + token,
      },
      body: JSON.stringify(selectedAttorney),
    });
    if (response.ok) {
      return true;
    } else {
      return false;
    }
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
};

export const getAttorney = async (year, stock_keyword, deadline, token) => {
  try {
    const response = await fetch(`${S_1_0}/attorney/list/?year=${year}&stock_keyword=${stock_keyword}&deadline=${deadline}`, {
      method: "GET",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        "Authorization": "Bearer " + token,
      },
    });
    if (response.ok) {
      const attorney = await response.json();
      return attorney;
    } else {
      return null;
    }
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
};

export const getAttorneyByUser = async (id, token) => {
  try {
    const response = await fetch(`${S_1_0}/attorney/userlist/?user_id=${id}`, {
      method: "GET",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        "Authorization": "Bearer " + token,
      },
    });
    if (response.ok) {
      const attorney = await response.json();
      return attorney;
    } else {
      return null;
    }
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
};

export const deleteAttorney = async (attorneys, token) => {
  try {
    const response = await fetch(`${S_1_0}/attorney/delete/`, {
      method: "POST",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        "Authorization": "Bearer " + token,
      },
      body: JSON.stringify(attorneys),
    });
    if (response.ok) {
      return true;
    } else {
      return false;
    }
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
};

export const getAttorneyInfo = async (id, token) => {
  try {
    const response = await fetch(`${S_1_0}/attorney/listinfo/?id=${id}`, {
      method: "GET",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        "Authorization": "Bearer " + token,
      },
    });
    if (response.ok) {
      const attorneyListInfo = await response.json();
      return attorneyListInfo;
    } else {
      return null;
    }
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
};

export const confirmAttorney = async (confirmList, token) => {
  try {
    const response = await fetch(`${S_1_0}/attorney/confirm/`, {
      method: "POST",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        "Authorization": "Bearer " + token,
      },
      body: JSON.stringify({
        ids: confirmList,
        confirm:1
    }),
    });
    if (response.ok) {
      return true;
    } else {
      return false;
    }
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
};

export const getAttorneyListByStock = async (token, selectedYear, stock_keyword, deadline) => {
  try {
    const response = await fetch(`${S_1_0}/attorney/liststocktotal/?year=${selectedYear}&stock_keyword=${stock_keyword}&deadline=${deadline}`, {
      method: "GET",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        "Authorization": "Bearer " + token,
      },
    });
    if (response.ok) {
      const attorneyList = await response.json();
      return attorneyList;
    } else {
      return null;
    }
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
};

export const voidAttorney = async (toggleStockIds, user_login, id, notes, separateUploadArrorney, isSeparateRemove, voidCount, token) => {
  let voidList = [];

  if(isSeparateRemove) {
    const tempList = {
      user_login : user_login,
      operator: id,
      attorney_id: separateUploadArrorney.attorneyId,
      stock_code: separateUploadArrorney.stockCode,
      void_amount: voidCount,
      note: notes
    }
    voidList.push(tempList)
  } else {
    toggleStockIds.forEach(attorney => {
      const tempList = {
        user_login : user_login,
        operator: id,
        attorney_id: attorney.attorneyId,
        stock_code: attorney.stockCode,
        void_amount: attorney.confirm_count - attorney.void_count,
        note: notes
      }
      voidList.push(tempList)
    });
  }

  try {
    const response = await fetch(`${S_1_0}/voidattorney/add/`, {
      method: "POST",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        "Authorization": "Bearer " + token,
      },
      body: JSON.stringify(voidList),
    });
    if (response.ok) {
      return true;
    } else {
      return false;
    }
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
};

export const getAttorneyListByUser = async (token, selectedYear) => {
  try {
    const response = await fetch(`${S_1_0}/attorney/listusertotal/?year=${selectedYear}`, {
      method: "GET",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        "Authorization": "Bearer " + token,
      },
    });
    if (response.ok) {
      const attorneyList = await response.json();
      return attorneyList;
    } else {
      return null;
    }
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
};

export const editAttorney = async (attorney, auto_pic, manual_pic, token) => {
  try {
    const response = await fetch(`${S_1_0}/attorney/edit/`, {
      method: "POST",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        "Authorization": "Bearer " + token,
      },
      body: JSON.stringify({
        id: attorney.attorneys_id,
        stock_code: attorney.stock_code,
        auto_pic : auto_pic,
        manual_pic: manual_pic,
        notes: attorney.note
    }),
    });
    if (response.ok) {
      return true;
    } else {
      return false;
    }
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
};

export const getAttorneyVoidData = async (attorney, token) => {
  try {
    const response = await fetch(`${S_1_0}/voidattorney/listfromidandstock/?attorney_id=${attorney.id}&stock_code=${attorney.stock_code}`, {
      method: "GET",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        "Authorization": "Bearer " + token,
      },
    });
    if (response.ok) {
      const attorneyList = await response.json();
      return attorneyList;
    } else {
      return null;
    }
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
};