import { S_1_0 } from "../configs/config";

export const getScreenshot = async (user_login, stock_code, specicName, certType, year, main_user, token) => {
  try {
    const response = await fetch(`${S_1_0}/searchshot/`, {
      method: "POST",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        "Authorization": "Bearer " + token,
      },
      body: JSON.stringify({
        user_login: user_login,
        stock_keyword: stock_code,
        name: specicName,
        year: parseInt(year),
        main_user: main_user,
        cert_type: certType
      }),
    });
    if (response.ok) {
      const url = await response.json();
      return url;
    } else {
      return null;
    }
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
};

export const getScreenshotData = async (user_login, stock_code, specicName, mergeUnit, year, token) => {
  try {
    const response = await fetch(`${S_1_0}/searchputshot/`, {
      method: "POST",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        "Authorization": "Bearer " + token,
      },
      body: JSON.stringify({
        user_login: user_login,
        stock_code: stock_code,
        specic_name: specicName,
        merge_unit: parseInt(mergeUnit),
        year: parseInt(year)
    }),
    });
    if (response.ok) {
      const url = await response.json();
      return url;
    } else {
      return null;
    }
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
};

export const outputshot = async (user_login, mergeUnit, pic_path, token) => {
  try {
    const response = await fetch(`${S_1_0}/outputshot/`, {
      method: "POST",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        "Authorization": "Bearer " + token,
      },
      body: JSON.stringify({
        user_login : user_login,
        merge_unit : mergeUnit,
        pic_path: pic_path
      }),
    });
    
    if (response.ok) {
      const url = await response.json();
      return url;
    } else {
      return null;
    }
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
};