import { S_1_0 } from "../configs/config";

export const getBillboardList = async (token) => {
  try {
    const response = await fetch(`${S_1_0}/billboard/list/`, {
      method: "GET",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        "Authorization": "Bearer " + token,
      }
    });
    if (response.ok) {
      const uploadFileCount = await response.json();
      return uploadFileCount;
    } else {
      return null;
    }
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
};

export const addBillboard = async (title, content, token) => {
  try {
    const response = await fetch(`${S_1_0}/billboard/add/`, {
      method: "POST",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        "Authorization": "Bearer " + token,
      },
      body: JSON.stringify({
        title: title,
        content: content,
      }),
    });
    if (response.ok) {
      return true;
    } else {
      return false;
    }
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
};

export const editBillboard = async (id, title, content, token) => {
  try {
    const response = await fetch(`${S_1_0}/billboard/edit/`, {
      method: "POST",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        "Authorization": "Bearer " + token,
      },
      body: JSON.stringify({
        id: id,
        title: title,
        content: content
      }),
    });
    if (response.ok) {
      return true;
    } else {
      return false;
    }
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
};

export const removeBillboard = async (id, token) => {
  try {
    const response = await fetch(`${S_1_0}/billboard/delete/`, {
      method: "POST",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        "Authorization": "Bearer " + token,
      },
      body: JSON.stringify([{
        id: id
      }]),
    });
    if (response.ok) {
      return true;
    } else {
      return false;
    }
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
};